import "./StatusBar.css";

import React, { Component } from "react";
import ProgressionTracker from "../ProgressionTracker/ProgressionTracker"
import { RideRequestStatus } from "../../../../../const/RideRequestStatus";
import { Trans } from "react-i18next";
import { utcStringToLocalString } from "../../../../../helpers/dateHelper";
import AnimatedPlusButton from "../../../../../components/AnimatedPlusButton/AnimatedPlusButton";

var moment = require('moment-timezone');

function minutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const displayMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
  return [hours, displayMinutes];
}

class StatusBar extends Component {
  state = {
    showingDriverFound: false,
    expanded: true,
  };

  async componentDidMount() {
    const expanded = this.props.expanded || true;
    this.setState({ expanded });
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.requestStatus === RideRequestStatus.NOT_YET_ACCEPTED &&
      this.props.requestStatus === RideRequestStatus.LIVE
    ) {
      this.setState({ showingDriverFound: true });
      const that = this;
      setTimeout(() => {
        that.setState({ showingDriverFound: false });
      }, 2000);
    }
  }

  expandSection = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    let content = null;
    const style = this.props.style;

    const now = moment().utc();
    const expectedDeliveredByDatetime = moment.utc(this.props.registeredTimes.expectedDeliveredByDatetime);
    const expectedDeliveredByDatetimeDelta = moment.duration(expectedDeliveredByDatetime.diff(now));
    const expectedDeliveredByDatetimeDeltaMins = (expectedDeliveredByDatetime.isAfter(now)) ? Math.round(expectedDeliveredByDatetimeDelta.asMinutes()) : -1;

    const serviceType = [2,3,4].includes(this.props.rideServiceTypeId) ? 'DELIVERY' : null;

    if (this.state.showingDriverFound) {
      content = (
        <div className="status-container">
          <p>{<Trans>DRIVER_FOUND</Trans>}</p>
          {/* Disabling the loading gif for now - will revisit based on user feedback */}
          {/* <div className="info-img-wrapper">
            <div className="loading-img-complete" style={{ backgroundColor: style && style.primaryColor }} />
          </div> */}
          <p className="disclaimer-map">
            <Trans>CONTENT_DISCLAIMER</Trans>
          </p>
        </div>
      );
    } else {
      switch (this.props.requestStatus) {
        // switch (this.props.requestStatus) { 
        case RideRequestStatus.NOT_YET_ACCEPTED:
          const startMinutes = Math.max(expectedDeliveredByDatetimeDeltaMins - 15, 0);
          const endMinutes= Math.max(expectedDeliveredByDatetimeDeltaMins, 15);
          const [startDisplayHours, startDisplayMinutes] = minutesToHours(startMinutes);
          const [endDisplayHours, endDisplayMinutes] = minutesToHours(endMinutes);

          // console.log(expectedDeliveredByDatetime,expectedDeliveredByDatetimeDelta, expectedDeliveredByDatetimeDeltaMins, endMinutes)
          content = (
            <div className="status-container">
              <p>{<Trans>REQUEST_START</Trans>}</p>
              <p><i>
                {expectedDeliveredByDatetimeDeltaMins <= 0 && <Trans values={{ context: serviceType }}>EXPECTED_DELIVERY_TIME_OVER</Trans>}
                
                {(expectedDeliveredByDatetimeDeltaMins > 0 && expectedDeliveredByDatetimeDeltaMins < 60) && <Trans i18nKey="EXPECTED_DELIVERY_TIME_MINUTES" values={{
                  start: startDisplayMinutes,
                  end: endDisplayMinutes,
                  context: serviceType
                }} components={[<strong />]} />}

                {(expectedDeliveredByDatetimeDeltaMins >= 60 && expectedDeliveredByDatetimeDeltaMins < (24 * 60)) && <Trans i18nKey="EXPECTED_DELIVERY_TIME_HOURS" values={{
                  startHours: startDisplayHours,
                  startMinutes: startDisplayMinutes,
                  endHours: endDisplayHours,
                  endMinutes: endDisplayMinutes,
                  context: serviceType
                }} components={[<strong />]} />}


                {expectedDeliveredByDatetimeDeltaMins >= (24 * 60) && <Trans i18nKey="EXPECTED_DELIVERY_TIME_DAYS" values={{
                  days: Math.floor(endMinutes / (24 * 60)),
                  context: serviceType
                }} components={[<strong />]} />}
                
              </i></p>
              {/* Disabling the loading gif for now - will revisit based on user feedback */}
              {/* <div className="info-img-wrapper">
                <div className="loading-img" style={{ backgroundColor: style && style.primaryColor }} />
              </div> */}
              <p className="disclaimer-map">
                <Trans>CONTENT_DISCLAIMER</Trans>
              </p>
            </div>
          );
          break;
        case RideRequestStatus.CANCELLED_BY_RIDER:
          content = (
            <div className="status-container">
              <p>{<Trans>STORE_CANCELLED</Trans>}</p>
              <div className="info-img-wrapper">
                <div className="info-img" style={{ backgroundColor: style && style.primaryColor }} />
              </div>
            </div>
          );
          break;
        case RideRequestStatus.CANCELLED_BY_DRIVER:
          content = (
            <div className="status-container">
              <p>{this.props.isMiaRide ? <Trans>DRIVER_CANCELLED</Trans> : <Trans>DRIVER_CANCELLED_NO_RE_DISPATCH</Trans>}</p>
              <div className="info-img-wrapper">
                <div className="people-img" style={{ backgroundColor: style && style.primaryColor }} />
              </div>
            </div>
          );
          break;
        case RideRequestStatus.CANCELLED_BY_COMMUNITY:
          content = (
            <div className="status-container">
              <p>{<Trans>COOP_CANCELLED</Trans>}</p>
              <div className="info-img-wrapper">
                <div className="people-img" style={{ backgroundColor: style && style.primaryColor }} />
              </div>
            </div>
          );
          break;
        case RideRequestStatus.SUCCESS:
          content = (
            <div className="status-container">
              <p><Trans>PACKAGE_DELIVERED</Trans>
                {
                  utcStringToLocalString(this.props.successDatetime)
                    .toString()
                    .split(" ")[0]
                }{" "}
                <Trans>AT</Trans>{" "}
                {utcStringToLocalString(this.props.successDatetime).split(" ")[1]}
              </p>
            </div>
          );
          break;
        case RideRequestStatus.LIVE:
        case RideRequestStatus.LIVE_OTHER_STEP: //Both return the ProgressionTracker. Necessary for multi-destination
          content = (
            <>
              <ProgressionTracker
                eta={this.props.eta}
                timeToPickupLocation={this.props.timeToPickupLocation}
                timeFromPickupLocationToDestination={this.props.timeFromPickupLocationToDestination}
                status={this.props.status}
                requestStatus={this.props.requestStatus}
                rideServiceTypeId={this.props.rideServiceTypeId}
                registeredTimes={this.props.registeredTimes}
                style={style}
              />
              <p className="disclaimer-map">
                <Trans>CONTENT_DISCLAIMER</Trans>
              </p>
            </>
          );
          break;
        default:
          break;
      }
    }

    return (
      <section className="tracking-link-status-container">
        <div className="status-title">
          <h4 style={(this.props.style && this.props.style.titleColor) ? {color: this.props.style.titleColor} : {}}><Trans>STATUS</Trans></h4>
          <AnimatedPlusButton
            color={style && style.primaryColor}
            stroke="3"
            width="17"
            isInitiallyRotated={true}
            onClick={this.expandSection}
          />
        </div>
        <div style={{color: this.props.style && this.props.style.textColor}}>
                  {this.state.expanded && content}
        </div>

      </section>
    )
  }
}

export default StatusBar;
