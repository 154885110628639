import React, { Component } from "react";
import {
  getConfirmationAlert,
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
} from "../../helpers/alertHelper";
import { Button, Table } from 'react-bootstrap';
import ReactTable from "react-table";
import { RideRequestStatus, RideRequestStatusV2, RideRequestStatusV2_LABELS } from "../../const/RideRequestStatus";
import { RideTypes } from "../../const/RideTypes";
import { Trans } from "react-i18next";
import {  } from "../../helpers/apiHelper";
import { connect } from "react-redux";
import { isUndefined } from "../../helpers/viewsHelper";
import { utcStringToLocalString } from "../../helpers/dateHelper";
import classnames from "classnames";
import i18n from "i18next";
import styles from "../OrderRide/OrderRideStyle.module.css";
import {getCorporateRides, cancelRide} from "../../helpers/apiHelper"; 
import CancelIcon from "../../assets/img/cancel.svg";
import OkIcon from "../../assets/img/ok.svg";

class LiveTrackerList extends Component {
  state = {
    rides: [],
    alert: null,
    showByCustomerRide: true,
    rides: [],
    driver_routes: []
  };

  componentWillMount() {
    getCorporateRides(0, Number.MAX_SAFE_INTEGER).then(data => {
        console.log()
        this.setState({
          rides: data.customer_rides,
          driver_routes: data.driver_routes
        })
    });
  }

  componentDidUpdate(prevProps) {
  }


  showRide = (trackingId) => {
    const win = window.open("/live_tracker?tracking_id=" + trackingId, "_blank");
    win.focus();
  };

  getRideStatusStyle = (status) => {
    let color = null;
    switch (status) {
      case RideRequestStatus.SUCCESS:
        color = "green";
        break;
      case RideRequestStatus.CANCELLED_BY_COMMUNITY:
      case RideRequestStatus.CANCELLED_BY_DRIVER:
      case RideRequestStatus.CANCELLED_BY_RIDER:
      case RideRequestStatus.UNKNOWN_CANCELLATION:
      case RideRequestStatus.NO_DRIVER:
        color = "red";
        break;
      case RideRequestStatus.LIVE:
      case RideRequestStatus.LIVE_OTHER_STEP:
        color = "orange";
        break;
      case RideRequestStatus.NOT_YET_ACCEPTED:
        color = "blue";
        break;
      case RideRequestStatus.SCHEDULED_FOR_LATER:
        color = "purple";
        break;
      default:
        break;
    }
    return color;
  };

  successHandlerAlert = (message) => {
    this.setState({
      alert: getSuccessAlert(
        () => this.hideAlert(),
        <Trans>SUCCESS</Trans>,
        message
      ),
    });
  };

  errorHandlerAlert = (error, msg) => {
    this.setState({
      alert: getErrorAlert(() => this.hideAlert(), error, msg),
    });
  };

  loadingHandlerAlert = () => {
    this.setState({
      alert: getLoadingAlert(),
    });
  };

  confirmationAlertHandler = (action, tracking_id) => {
    switch (action) {
      case "cancel_ride":
        this.setState({
          alert: getConfirmationAlert(
            () => this.cancelRideObj(tracking_id),
            () => this.hideAlert(),
            <Trans>ATTENTION</Trans>,
            <Trans>ARE_YOU_SURE_CANCEL</Trans>
          ),
        });
        break;
      default:
        return
    }
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  cancelRideObj = async (tracking_id) => {
    this.loadingHandlerAlert();
    const response = await cancelRide(tracking_id);
    console.log('response', response);
    if (response) {
      this.successHandlerAlert(<Trans>CANCEL_SUCCESS</Trans>);
    } else {
      this.errorHandlerAlert(<Trans>ERROR</Trans>, <Trans>CANCEL_ERROR</Trans>);
    }
  }

  toggleCustomerOrderView = () => {
    this.setState({
      showByCustomerRide: true
    })
  }

  toggleDriverRouteView = () => {
    this.setState({
      showByCustomerRide: false
    })
  }

  showPerCustomer = () => {
    return (
      <div style={{ backgroundColor: "white", padding: "2%" }}>
      {this.state.alert}
        <ReactTable
        previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
          data={this.state.rides}
          filterable
          getTrProps={(state, rowInfo) => {
            return {
              style: {
                cursor: "pointer",
              },
              onClick: () => {
                this.showRide(rowInfo.original.business_tracking_id);
              },
            };
          }}
          columns={[
            {
              Header: <Trans>DATE</Trans>,
              id: "date",
              accessor: (ride) => utcStringToLocalString(ride.scheduled_datetime),
            },
            {
              Header: <Trans>STATUS</Trans>,
              id: "status",
              accessor: (ride) => {
                const color = this.getRideStatusStyle(ride.status);
                return (
                  <span style={{ color: color }}>
                    <Trans>{RideRequestStatusV2_LABELS[RideRequestStatusV2[ride.status]]}</Trans>
                  </span>
                );
              },
            },
            {
              Header: <Trans>CUSTOMER_FIRST_NAME</Trans>,
              id: "customerFirstName",
              accessor: (ride) => ride.customer_first_name,
            },
            {
              Header: <Trans>CUSTOMER_LAST_NAME</Trans>,
              id: "customerLastName",
              accessor: (ride) => ride.customer_last_name,
            },
            {
              Header: <Trans>CUSTOMER_PHONE</Trans>,
              id: "customerPhone",
              accessor: (ride) => ride.customer_phone,
            },
            {
              Header: <Trans>FROM_ADDRESS</Trans>,
              id: "fromAddress",
              accessor: (ride) => ride.from_address,
            },
            {
              Header: <Trans>TO_ADDRESS</Trans>,
              id: "toAddress",
              accessor: (ride) => ride.to_address,
            },
            {
              Header: <Trans>RIDE_TYPE</Trans>,
              id: "rideType",
              accessor: (ride) => (
                <Trans>{RideTypes[ride.ride_service_type_id]}</Trans>
              ),
            },
            {
              Header: <Trans>ACTIONS</Trans>,
              id: "cancelRide",
              accessor: (ride) => (
                <span
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                <Button bsSize="xsmall"
            bsStyle="danger"
             style={{ padding: "1%", margin: 0, height:20 }} onClick={() => this.confirmationAlertHandler("cancel_ride", ride.tracking_id)}><Trans>CANCEL</Trans></Button>
             </span>
              ),
            },
          ]}
          className="-highlight"
          noDataText={<Trans>NO_LIVE_RIDES</Trans>}
        />
      </div>
    );
  }

  getRouteRows = (entry) => {
    const rows = entry.rides
    console.log('rows', rows);
    return (
      <>
        {rows.map(row => {
          return (
            <tr className="driver-routes-subrow" onClick={() => {this.showRide(row.business_tracking_id);}}>
              <td>{row.end_step_order + 1}</td>
              <td>{utcStringToLocalString(row.scheduled_datetime)}</td>
              <td>{utcStringToLocalString(row.onsite_datetime)}</td>
              <td>{utcStringToLocalString(row.expected_delivered_by_datetime)}</td>
              <td>{row.from_address}</td>
              <td>{row.to_address}</td>
              <td>{row.pick_up_company_name}</td>
              <td>{row.order_number}</td>
              <td>{row.customer_phone}</td>
            </tr>
          );
        })}
      </>
    );

  }

  getIcon = (bool) => {
    if (bool) {
      return <img src={OkIcon} />;
    } else {
      return <img src={CancelIcon} />;
    }
  }

  onSubClick = (event) => {
    console.log('event', event);
    return (
      <div>
        <Table striped size="sm" className="sub-table">
          <thead>
            <tr>
              <th><Trans>STEP_ORDER</Trans></th>
              <th><Trans>SCHEDULED_DATE</Trans></th>
              <th><Trans>ONSITE_DATE</Trans></th>
              <th><Trans>DELIVERED_BY_DATE</Trans></th>
              <th><Trans>FROM_ADDRESS</Trans></th>
              <th><Trans>TO_ADDRESS</Trans></th>
              <th><Trans>BUSINESS_NAME</Trans></th>
              <th><Trans>ORDER_NUMBER</Trans></th>
              <th><Trans>CUSTOMER_PHONE</Trans></th>
            </tr>
          </thead>
          <tbody className="table-api-subtable-container">
            {this.getRouteRows(event.original)}
          </tbody>
        </Table>
      </div>
    )
  }

  showPerDriverRoute = () => {
    console.log(this.state.driver_routes);
    return  (
      <div style={{ backgroundColor: "white", padding: "2%" }}>
      <button
        className={classnames(styles.buttonActive)}
        style={{ padding: "16px 48px" }}
        onClick={(e) => this.toggleCustomerOrderView(true)}>
        {i18n.t("SHOW_BY_CUSTOMER_RIDE")}
      </button>
      {this.state.alert}
        <ReactTable
        previousText={i18n.t('PREVIOUS')} nextText={i18n.t('NEXT')} loadingText={i18n.t('LOADING')}
          data={this.state.driver_routes}
          SubComponent= {true && this.onSubClick}
          getTrProps={(state, rowInfo) => {
            return {
              style: {
                cursor: "pointer",
              },
            };
          }}
          columns={[
            {
              Header: <Trans>DATE</Trans>,
              id: "date",
              accessor: (dride) => utcStringToLocalString(dride.driver_route.scheduled_datetime),
            },
            {
              Header: <Trans>IS_STARTED</Trans>,
              id: "started",
              accessor: (dride) => dride.driver_route.started,
              Cell: (e) => {
                return this.getIcon(e.original.driver_route.driver_found);
              },
            },
            {
              Header: <Trans>IS_EN_ROUTE</Trans>,
              id: "driver_found",
              accessor: (dride) => dride.driver_route.driver_found,
              Cell: (e) => {
                return this.getIcon(e.original.driver_route.driver_found);
              },
            },
            {
              Header: <Trans>NUMBER_CUSTOMER_RIDES</Trans>,
              id: "length_rides",
              accessor: (dride) => dride.rides.length,
            }
            ]}
          className="-highlight"
          noDataText={<Trans>NO_LIVE_RIDES</Trans>}
        />
      </div>
    );
  }

  render() {
    if (this.state.showByCustomerRide) {
      return this.showPerCustomer();
    } else {
      return this.showPerDriverRoute();
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(LiveTrackerList);

