import { Button, Col, Dropdown, Grid, MenuItem, Row } from "react-bootstrap";
import React, { Component } from "react";
import {
  //getAccountSettings,
  //getAddressAutoComplete,
  //getAddressDetails,
  //getCommunitySettings,
  //getQuote,
  //getRideServicesByType,
  //getSelectedCommunity
} from "../../helpers/communityApiHelper";
import { removeAccents } from '../util';
import {
  getAccountSettings,
  getRideServicesByType,
  getAddressAutoComplete,
  getAddressDetails,
  getQuote
} from "../../helpers/apiHelper";
import AutoComplete from "./AutoComplete";
import COMM_CONSTANTS from "../../const/CommunityConstants";
import Datetime from "react-datetime";
import DropdownMenu from "react-bootstrap/lib/DropdownMenu";
import DropdownToggle from "react-bootstrap/lib/DropdownToggle";
import PartialLoadingIcon from "../../components/LoadingIcon/PartialLoadingIcon";
import { convertToToken, getSymbol } from "../../helpers/paymentHelper";
import RidePreviewMap from "../Maps/RidePreviewMap";
import { RideTypeIds, RideServiceInfos } from "../../const/RideTypes";
import { Trans } from "react-i18next";
import classnames from "classnames";
import i18n from "i18next";
import styles from "./OrderRideStyle.module.css";
import { connect } from 'react-redux';
import { CurrencyFormatter } from "../../helpers/CurrencyFormatter";

var moment = require("moment-timezone");
let timeout = null;

class OrderRide1 extends Component {
  constructor(props) {
    super(props);

    const { hour, min,  cashPaymentOrderValue, deliveryFeePaidInCashAtDoor} = this.props.data;
    this.state = {
      hours: this.generateMenuItemRange(0, 23),
      mins: this.generateMenuItemRange(0, 59),
      currentSchedulingMessage: null,
      autoCompleteResults: [],
      showOptionsForStart: false,
      showOptionsForEnd: false,
      hour, min,
      quotes: [],
      communitySettings: {},
      isCashPaymentAllowed: false,
      deliveryFeePaidInCashAtDoor: false,
      cashPaymentOrderValue: cashPaymentOrderValue,

    }
  }
  async componentDidUpdate(prevProps) {
    // Check if selectedCommunityName has changed
    if (prevProps.selectedCommunityName !== this.props.selectedCommunityName) {
      await this.updateData();
    }
  }

  async componentDidMount() {
    // Get the current user's location and set the start location
    // input to the current address
    const accountSettings = await this.updateData();

    // No need to get pickup location from settings if it already has a value
    if (this.props.data.startLocation) return;

    this.props.onChange({ startLocation: accountSettings.default_address });

    //If permisisons are set:
    navigator.geolocation.getCurrentPosition((position) => {
      if (typeof position !== undefined) {
        const currentLat = position.coords.latitude;
        const currentLon = position.coords.longitude;
        this.props.onChange({
          currentCoordinates: { lat: currentLat, lon: currentLon },
        });
      }
    });
  }

  generateMenuItemRange = (min, max) => {
    const menuItems = [];
    for (let i = min; i <= max; i++) {
      const paddedNum = i.toFixed().padStart(2, '0');
      menuItems.push(
        <MenuItem
          eventKey={paddedNum}>
          {paddedNum}
        </MenuItem>
      )
    }
    return menuItems;
  }

  handleNowButton = () => {
    const dateNow = moment();
    const min = dateNow.minutes().toFixed().padStart(2, '0');
    const hour = dateNow.hours().toFixed().padStart(2, '0');
    this.props.onChange({
      onsiteDatetime: dateNow,
      isNow: true,
      datePickerActive: false,
      hour, min
    });
    this.setState({
      hour, min
    })
  }


  handleDatePickerButton = (onsiteDatetime) => {
    if (!onsiteDatetime) {
      onsiteDatetime = this.props.data.onsiteDatetime || moment();
    }
    this.props.onChange({
      onsiteDatetime,
      isNow: false,
      datePickerActive: true
    });
  }

  convertDatetime = () => {
    const { onsiteDatetime } = this.props.data;
    if (onsiteDatetime) {
      onsiteDatetime.set({ h: this.state.hour, m: this.state.min, s: 0 });
      this.props.onChange({ onsiteDatetime });
    }
  }

  async updateData() {
    const accountSettings = await getAccountSettings();
    console.log('accountSettings', accountSettings);
    const rideServiceIds = await getRideServicesByType().then(result => {
      // Remove cannabis type for common users
      //if (process.env.NODE_ENV === "production" && COMM_CONSTANTS && !COMM_CONSTANTS.SQDC_ACCOUNTS.includes(this.props.currentUserAccount)) delete result[RideTypeIds.CANNABIS];
      //if (process.env.NODE_ENV === "production" && COMM_CONSTANTS && !COMM_CONSTANTS.SHUTTLE_ACCOUNTS.includes(this.props.currentUserAccount)) delete result[RideTypeIds.SHUTTLE];
      return result;
    });

    //const communitySettings = await getCommunitySettingsMia(COMM_CONSTANTS.RIDE_SERVICE_VISIBILITY);
    //const isCashPaymentAllowed = await getCommunitySettingsMia(COMM_CONSTANTS.IS_CASH_PAYMENT_ALLOWED);

    this.setState({
      rideServiceIds: rideServiceIds,
      // communitySettings: (communitySettings.value) ? communitySettings.value : {},
      //isCashPaymentAllowed: (isCashPaymentAllowed.value) ? isCashPaymentAllowed.value : false,
    });
    return accountSettings;
  }

  handleChangeStartLocation(e) {
    const startLocation = removeAccents(e.target.value);
    this.props.onChange({
      startLocation,
      locationStepDone: false,
      selectedRideServiceId: -1,
    });
    this.setState({
      showOptionsForStart: true,
      showOptionsForEnd: false,
    }, this.getAddressAutoCompleteResults(startLocation));
  }

  handleChangeEndLocation(e) {
    const endLocation = removeAccents(e.target.value);
    this.props.onChange({
      endLocation,
      locationStepDone: false,
      selectedRideServiceId: -1
    });
    this.setState({
      showOptionsForStart: false,
      showOptionsForEnd: true,
    }, this.getAddressAutoCompleteResults(endLocation));
  }

  handleStartLocationAutoCompleteSelect(e) {
    this.setState({
      showOptionsForStart: false,
      autoCompleteResults: []
    });
    this.props.onChange({ startLocation: e.textContent }, () => this.handleLocationsForm());
  }

  handleEndLocationAutoCompleteSelect(e) {
    this.setState({
      showOptionsForEnd: false,
      autoCompleteResults: []
    });
    this.props.onChange({ endLocation: e.textContent }, () => this.handleLocationsForm());
  }

  handleAutoCompleteSelect(e) {
    if (this.state.selectedBox.id === "startLocation") {
      this.setState({
        showOptionsForStart: false,
      });
      this.props.onChange({ startLocation: e.textContent }, () => this.handleLocationsForm());
    } else if (this.state.selectedBox.id === "endLocation") {
      this.setState({ showOptionsForEnd: false });
      this.props.onChange({ endLocation: e.textContent }, () => this.handleLocationsForm());
    }
    this.setState({ autoCompleteResults: [] });
  }

  getAddressAutoCompleteResults(query) {
    // Using timeout to avoid sending requests to the API
    // on every keypress
    let { lat, lon } = this.props.data.currentCoordinates;
    clearTimeout(timeout);
    if (!query) {
      this.setState({ autoCompleteResults: [] });
      return;
    };

    timeout = setTimeout(() => {
      getAddressAutoComplete(lat, lon, query).then((data) => {
        console.log('data... received autocomplete', data);
        this.setState({ autoCompleteResults: data.data.predictions });
      });
    }, 250);
  }

  handleLocationInput(e) {
    this.setState({
      showOptionsForStart: false,
      showOptionsForEnd: false,
    });

    if (e.target.id === "startLocation") {
      this.setState({
        showOptionsForStart: true,
        showOptionsForEnd: false,
        selectedBox: e.target,
        autoCompleteResults: [],
      });
    } else if (e.target.id === "endLocation") {
      this.setState({
        showOptionsForEnd: true,
        showOptionsForStart: false,
        selectedBox: e.target,
        autoCompleteResults: [],
      });
    }
  }

  async handleLocationsForm() {
    const { startLocation, endLocation } = this.props.data;
  
    if (startLocation && endLocation) {
      this.setState({ showLoading: <PartialLoadingIcon /> });
  
      let fromLocation = await getAddressDetails(removeAccents(startLocation));
      fromLocation = fromLocation.data;
      let toLocation = await getAddressDetails(removeAccents(endLocation));
      toLocation = toLocation.data;
  
      console.log('fromLocation', fromLocation);
      console.log('toLocation', toLocation);
  
      const quotePromises = [];
  
      for (let rideTypeId of Object.values(RideTypeIds)) {
        if (rideTypeId in this.state.rideServiceIds) {
          console.log('rideTypeId', rideTypeId);
          console.log('this.state.rideServiceIds', this.state.rideServiceIds);
          for (let rideServiceId of Object.values(this.state.rideServiceIds[rideTypeId])) {
            quotePromises.push(
              getQuote(
                fromLocation.lat,
                fromLocation.lon,
                toLocation.lat,
                toLocation.lon,
                rideServiceId
              ).then((quoteFull) => ({
                service_id: rideTypeId,
                type_id: rideTypeId,
                quote: CurrencyFormatter.formatAmount(quoteFull.data.ride_total_charge, quoteFull.data.currency),
                amount: quoteFull.data.ride_total_charge,
                currency: quoteFull.data.currency,
                duration: quoteFull.data.duration,
              }))
            );
          }
        }
      }
  
      const quotes = await Promise.all(quotePromises);
  
      this.props.onChange({
        fromCoords: fromLocation,
        toCoords: toLocation,
        locationStepDone: true,
        quotes,
        fromLocation,
        toLocation,
      });
    
      this.setState({
        showLoading: null,
      });
    } else {
      this.props.onChange({ locationStepDone: false, selectedRideServiceId: -1 });
    }
  }

  selectRideTypeBox = () => {
    console.log('this.props.data.quotes', this.props.data.quotes);
    console.log('this.props.data.locationStepDone', this.props.data.locationStepDone);
    if (!this.props.data.locationStepDone) return [];
    const map = <RidePreviewMap {...this.props.data} fixedEta={this.props.data.quotes && this.props.data.quotes.length > 0 && this.props.data.quotes[0].duration || null} />;
    const getRideServiceBtn = (quoteObj, key) => {
      const { service_id: id, type_id: type, quote } = quoteObj;
      let img = RideServiceInfos[type].image || null;
      let translation = <Trans>{RideServiceInfos[type].text}</Trans> || null;
      return (
        <span style={{ margin: "0 5%" }} key={key}>
          <Button
            id={"serviceBtn_" + id}
            className={
              classnames(
                this.props.data.selectedRideServiceId === id ? "" : styles.grayscaleFade,
                styles.rideServiceBtn
              )
            }
            onClick={() => this.props.onChange({ selectedQuote: quoteObj, selectedRideServiceId: id, selectedRideServiceTypeId: type })}
          >
            <img
              style={{borderRadius: "50%", backgroundColor: 'white'}}
              src={img}
              alt={i18n.t(RideServiceInfos[type].text) + " icon"}
              className={classnames(styles.rideIcon,
                this.props.data.selectedRideServiceId === id ? styles.rideServiceBtnIcon : "")} />
            <p style={{ marginTop: 20, marginBottom: 0 }}>{translation}</p>
            <p style={{ marginTop: 0 }}>{quote}</p>
          </Button>
        </span>
      );
    };

    return (
      <div>
        <Row className="align-items-center">
          <div>
            <Col md={12} xs={12} style={{ textAlign: "center" }}>
              <div className={"ride-choice"} style={{ margin: "auto" }}>
                {this.props.data.quotes.map((quote, index) => {
                  return getRideServiceBtn(quote, index);
                })}
              </div>
            </Col>
          </div>
        </Row>
        <Row className="justify-content-md-center">
          <Col style={{ height: "52vh", width: "90%", margin: "auto" }}>
            <div>{map}</div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col style={{ height: "10vh", width: "90%", margin: "auto" }}>
            <div style={{ padding: "20px 0px" }}><i>**<Trans>MIA_PRICE_DISCLAIMER</Trans></i></div>
          </Col>
        </Row>
      </div>
    );
  };
  
  handleOrderAmountValueChange = (value) => {
    const cashPaymentOrderValue = value.replace(/\D[\.]/g, '')
    this.setState({cashPaymentOrderValue});
    const cashPaymentOrderValueToken = convertToToken(cashPaymentOrderValue, new Date(), false);
    this.props.onChange({
      cashPaymentOrderValue: cashPaymentOrderValueToken
    });
  }

  handleDeliveryFeePaidInCashAtDoorChange = (value) => {
    this.props.onChange({
      isDeliveryFeePaidInCashAtDoor: value
    });
  }

  render() {
    const {
      startLocation,
      startLocationOptionalInfo,
      endLocation,
      endLocationOptionalInfo,
      onsiteDatetime,
      isNow,
      datePickerActive,
      selectedRideServiceId,
      cashPaymentOrderValue,
      isDeliveryFeePaidInCashAtDoor

    } = this.props.data;
    const { onChange } = this.props;


    let cashOptionsRowComponent = null;

    if (this.state.isCashPaymentAllowed){
      cashOptionsRowComponent = [<Row>
        <Col md={12}>
          <p className={styles.formTitle}>
            {i18n.t("CASH_PAYMENT_ORDER_AMOUNT")}
          </p>
          <input
              name="cashPaymentOrderValue"
              value={this.state.cashPaymentOrderValue}
              type="text"
              pattern="[0-9]+([\.|,][0-9]+)?" 
              className={styles.inputFormTip}
              tabIndex={5}
              onChange={(e) => this.handleOrderAmountValueChange(e.target.value)}
              >
            </input>
          <input
              name="cashPaymentOrderValueCurrencyLabel"
              value={getSymbol()}
              className={styles.inputFormTipCurrency}
              tabIndex={6}
              readonly="true" >

            </input>
        </Col>
      </Row>,
      <Row className="justify-content-md-center">
        <Col style={{ height: "10vh", width: "90%", margin: "auto" }}>
          <div style={{ padding: "20px 0px" }}><i>**<Trans>MIA_CASH_AMOUNT_DISCLAIMER</Trans></i></div>
        </Col>
      </Row>,
      <Row>
        <Col md={12}>
          <p className={styles.formTitle}>
            {i18n.t("ASK_FOR_DELIVERY_FEE")}
          </p>
            <input
              type="checkbox"
              name="cashPayment"
              checked={isDeliveryFeePaidInCashAtDoor}
              onChange={(e) => {this.handleDeliveryFeePaidInCashAtDoorChange(e.target.checked)}}
            />
        </Col>
      </Row>,
      <Row className="justify-content-md-center">
        <Col style={{ height: "10vh", width: "90%", margin: "auto" }}>
          <div style={{ padding: "20px 0px" }}><i>**<Trans>MIA_CASH_AMOUNT_VALUE_DELIVERED_DISCLAIMER</Trans></i></div>
        </Col>
      </Row>,]
    }

    return (
      <div style={{ width: "100%" }}>
        <Grid className={styles.locationFormContainer}>
          <Row>
            <Col md={12} lg={12}>
              <p className={styles.formTitle}>
                {i18n.t("START_LOCATION")}
              </p>
              <div className={styles.formTitleLine} />
              <input
                id="startLocation"
                name="startLocation"
                placeholder={i18n.t("ADDRESS_PLACEHOLDER")}
                className={styles.inputForm}
                value={removeAccents(startLocation)}
                onChange={(e) => this.handleChangeStartLocation(e)}
                onClick={(e) => this.handleLocationInput(e)}>
              </input>
              <AutoComplete
                options={this.state.autoCompleteResults}
                idKey={"place_id"}
                valueKey={"description"}
                showOptions={this.state.showOptionsForStart}
                handleClick={(e) => this.handleStartLocationAutoCompleteSelect(e)}
              />
              <input
                name="startLocationOptionalInfo"
                placeholder={i18n.t("OPTIONAL")}
                className={styles.inputForm}
                value={startLocationOptionalInfo}
                onChange={(e) => onChange({ [e.target.name]: e.target.value })}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <p className={styles.formTitle}>
                {i18n.t("DESTINATION")}
              </p>
              <div className={styles.formTitleLine} />
              <input
                id="endLocation"
                name="endLocation"
                placeholder={i18n.t("ADDRESS_PLACEHOLDER")}
                className={styles.inputForm}
                value={removeAccents(endLocation)}
                onChange={(e) => this.handleChangeEndLocation(e)}
                onClick={(e) => this.handleLocationInput(e)}>
              </input>
              <AutoComplete
                options={this.state.autoCompleteResults}
                idKey={"id"}
                valueKey={"description"}
                showOptions={this.state.showOptionsForEnd}
                handleClick={(e) => this.handleEndLocationAutoCompleteSelect(e)} />
              <input
                name="endLocationOptionalInfo"
                placeholder={i18n.t("OPTIONAL")}
                className={styles.inputForm}
                onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                value={endLocationOptionalInfo}>
              </input>
            </Col>
          </Row>
          {cashOptionsRowComponent}
        </Grid>
        <div className={styles.center}>
          <p className={styles.title}>
            {i18n.t("PLANNED_DATE")}
            <div className={styles.orderRideTitleLine} />
          </p>
          {isNow ? null : <p>{i18n.t("DRIVER_WILL_ARRIVE_THAT_TIME")}</p>}
          <Grid className={styles.plannedDateContainer}>
            <Row className={styles.plannedDateForm}>
              <button
                className={classnames(styles.nowButton,
                  isNow ? styles.buttonActive : styles.buttonInactive)}
                style={{ padding: "16px 48px" }}
                onClick={(e) => this.handleNowButton(e)}>
                {i18n.t("OPTIONAL_RIGHT_NOW")}
              </button>
              <div className={styles.dateFormSeparator} />
              <div className={styles.dateContainer}>
                <Datetime
                  onChange={(date) => {
                    this.handleDatePickerButton(date);
                  }}
                  isValidDate={(currentDate) =>
                    currentDate.isAfter(moment().subtract(1, "days"))
                  }
                  value={onsiteDatetime}
                  dateFormat="YYYY/MM/DD"
                  timeFormat={false}
                  inputProps={{
                    style: { cursor: "pointer" },
                    className: classnames(styles.dateButton,
                      datePickerActive ? styles.buttonActive : styles.buttonInactive),
                    onClick: () => this.handleDatePickerButton(),
                  }}
                />
                <div className={styles.dateFormLine} />
                <div className={styles.timeContainer}>
                  <div
                    className={classnames(styles.customDropdown, isNow ? "" : styles.evaBackground)}>
                    <p>{this.state.hour}</p>
                    <div className={styles.dropdownSeparator} />
                    <Dropdown
                      onClick={() => {
                        onChange({
                          isNow: false,
                          datePickerActive: true
                        });
                      }}
                      onSelect={(e) => this.setState({ hour: e })}>
                      <DropdownToggle
                        className={styles.dropdownToggle}>
                      </DropdownToggle  >
                      <DropdownMenu
                        className={styles.dropdownMenu}>
                        {this.state.hours}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <p className={styles.h}>H</p>
                  <div className={classnames(styles.customDropdown, isNow ? "" : styles.evaBackground)}>
                    <p>{this.state.min}</p>
                    <div className={styles.dropdownSeparator} />
                    <Dropdown
                      onClick={() => {
                        onChange({
                          isNow: false,
                          datePickerActive: true
                        });
                      }}
                      onSelect={(e) => this.setState({ min: e })}>
                      <DropdownToggle
                        className={styles.dropdownToggle}
                      >
                      </DropdownToggle  >
                      <DropdownMenu
                        className={styles.dropdownMenu}>
                        {this.state.mins}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <p className={styles.h}>M</p>
                </div>
              </div>
            </Row>
          </Grid>
        </div>
        <Grid style={{ width: "100%" }}>
          {this.state.showLoading}
          {this.selectRideTypeBox()}
          {selectedRideServiceId > -1 && <Row>
            <button className={styles.nextBtn} onClick={() => {
              this.convertDatetime();
              this.props.nextStepClick();
            }}>
              <p className={styles.nextText}>{i18n.t("NEXT")}</p>
            </button>
          </Row>}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    //currentUserAccount: getSelectedCommunity().riderAccount,
    selectedCommunityName: state.userData.selectedCommunityName
  };
};

export default connect(mapStateToProps)(OrderRide1);