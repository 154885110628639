import * as actionTypes from "./action_types";

import { persistor } from "../store/index";

export function updateCommunity(payload) {
  return { type: actionTypes.UPDATE_COMMUNITY, payload };
}

export function loginSuccess(payload) {
  return { type: actionTypes.ON_LOG_IN_SUCCESS, payload };
}

export function chooseCommunity(payload) {
  return { type: actionTypes.ON_UPDATE_SELECT_COMMUNITY, payload}
}

export function logout(payload) {
  persistor.purge();
  return { type: actionTypes.LOGOUT, payload };
}

export function updateRides(payload) {
  return { type: actionTypes.UPDATE_RIDES, payload };
}

export function reportMiaErrorAt(payload) {
  return { type: actionTypes.REPORT_MIA_ERROR_AT, payload };
}

export function updateExchangeRates(payload) {
  return { type: actionTypes.UPDATE_EXCHANGE_RATES, payload};
}
