import "./LiveTracker.css";

import React, { Component } from "react";
import {
  getTrackingObject,
  getCommunitySettings,
  getRideRequestStatus,
  getTrackingStylesPublic,
  getActiveRide,
  getCompletedRide,
  getDriverPosition
} from "../../helpers/apiHelper";

import { store } from "../../store/index";
import { isMobile, isUndefined } from "../../helpers/viewsHelper";
import { CommunitySettingCodes } from "../../const/CommunitySettings";
import Drawer from "./Drawer/Drawer";
import Separator from "./CustomComponents/Separator/Separator";
import EvaLogo from "../../assets/img/Eva_logo.png";
import HelpScreen from "./Drawer/HelpScreen/HelpScreen";
import Invalid from "./Invalid/Invalid";
import { LiveRideStatus } from "../../const/ActiveRideStatus";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import Map from "./Map/Map";
import { RideRequestStatus } from "../../const/RideRequestStatus";
import { Trans } from "react-i18next";
import adImg from "../../assets/img/tracking_link_ad_1.png";
import arrowImg from "../../assets/img/eva-arrow.png";
import grayArrowImg from "../../assets/img/gray-arrow.png";
import { connect } from "react-redux";
import { defaultProfileImgHash } from "../../variables/secretEnvVariables";
import { getOSRMDirections } from "../../helpers/routingHelper";
import i18n from "../../i18n";
import plusImg from "../../assets/img/eva-plus.png";
import smiley from "../../assets/img/smiley.png";
import {formatPhoneNumber} from "../../helpers/viewsHelper";

var moment = require('moment-timezone');

const REFRESH_RATE_SECONDS = 10;
const REFRESH_RATE_MINUTES_DIRECTIONS = 2;
const REFRESH_RATE_DYNAMIC_DATA_SECONDS = 4;
const DRIVER_ACCEPT_LIVE_DELAY = 5;

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

class LiveTracker extends Component {
  state = {
    key: 0,
    currentLanguage: {
      Key: i18n.language,
      Label: i18n.language.toUpperCase(),
    },
    loading: true,
    successPopup: null,
    showInvalidLink: false,
    minimizeDrawer: false,
    isCompletedRideInDatabase: false,
    isMiaRide: false,
    evaLogo: EvaLogo,
    isRideCancelled: false,
    showLanguageDropdown: false
  };

  componentDidMount = async () => {
    const isMiaRide = !isUndefined(this.props.tracking_id) && this.props.tracking_id !== null;
    const isClientRide = false;

    const response = await getTrackingObject(this.props.tracking_id);

    //Load community settings
    const communitySettings = await getCommunitySettings(response.zone_id);
    const customCommMessage = communitySettings.find(e => e.code === CommunitySettingCodes.TRACKING_LINK_MESSAGE);
    if (customCommMessage) {
      this.setState({customCommMessage: customCommMessage.value});
    }

    //If its demo, show only static data and ignore all actions
    if (this.props.showDemo) {
      this.loadDemo();
      this.changeLanguage();
    } else {
    this.changeLanguage();
    // invalid link if ride does not exist in database (either in active ride or completed ride)
    if ((!isUndefined(response) && response.status === 500) || response instanceof Error) {
      this.setState({ showInvalidLink: true });
    } else {
      let tracking_obj = response
      const trackingLinkStyles = await getTrackingStylesPublic(tracking_obj.ride_request_id);
      const activeStyle = trackingLinkStyles.find(e => e.is_active === true);
      const style = this.loadStyle(activeStyle);
      let evaLogo = EvaLogo
      //if (style) {
      //  if (style.companyLogo) evaLogo = style.companyLogo;
      //}

      

      if (isMiaRide) {
        this.setState({
          ride_request_id: tracking_obj.ride_request_id,
          ride_service_type_id: tracking_obj.ride_service_type_id,
          tracking_id: tracking_obj.tracking_id,
          is_business: tracking_obj.is_business,
          scheduledDatetime: tracking_obj.scheduled_datetime,
          customerPhone: tracking_obj.customer_phone,
          businessPhone: tracking_obj.pickup_phone,
          expectedDeliveredByDatetime: tracking_obj.expected_delivered_by_datetime,
          pickup: {
            coords: [tracking_obj.from_longitude, tracking_obj.from_latitude],
            address: tracking_obj.from_address,
          },
          destination: {
            coords: [tracking_obj.to_longitude, tracking_obj.to_latitude],
            address: tracking_obj.to_address,
          },
          note: tracking_obj.note,
          evaLogo,
          isMiaRide,
        });
      }

      await this.checkStatus();
      const that = this;
      this.statusInterval = setInterval(() => {
        that.checkStatus();
      }, REFRESH_RATE_SECONDS * 1000);
    }
    await this.setState({ loading: false });
  }
  };

  componentDidUpdate(prevProps) {
    if (this.props.showDemo) {
      if (this.props.showDemo.refreshId !== prevProps.showDemo.refreshId) {
        this.loadDemo();
      }
    }
  }

  async loadDemo() {
    const style = this.loadStyle(this.props.showDemo.style);
    style.refreshId = this.props.showDemo.refreshId;
      let evaLogo = EvaLogo
      if (style) {
        if (style.companyLogo) evaLogo = style.companyLogo;
      }

      await this.setState({
        is_business: true,
        scheduledDatetime: new Date(),
        expectedDeliveredByDatetime: new Date(),
        pickup: {
          coords: ["-73.589135", "45.521384"],
          address: "Pickup address",
        },
        destination: {
          coords: ["-73.590287", "45.477749"],
          address: "Destination Address",
        },
        note: "Customer note",
        style,
        evaLogo,
        loading: false,
        requestStatus: this.props.showDemo.showPage,
        rideStatus: LiveRideStatus.ON_WAY_TOWARDS_PICKUP,
        ride: {},
        driverProfile: {
        "first_name": "Demo",
        "last_name": "Driver",
        "phone": "0000000000",
        "vehicle_brand": "Honda",
        "vehicle_color": "blue",
        "vehicle_year": "2020",
        "vehicle_mileage": 10000,
        "vehicle_model": "Element",
        "vehicle_registration_plate": "N21YWL",
        "vehicle_type_id": 0,},
        isCompletedRideInDatabase: false,
        driverImage: "",
        nextDriverPosition: ["-73.585131", "45.532250"],
        currentDriverPosition: ["-73.585131", "45.532250"],
        ride: {
          "driver_onsite_time": null,
          "driver_profile_image_hash": null,
          "driver_vehicle": {
              "allowed_ride_services": [
                  1
              ],
              "created_at": "2022-07-22T21:26:51.500000",
              "driver": "driver",
              "id": 9999,
              "vehicle_allowed_multi": true,
              "vehicle_brand": "Honda",
              "vehicle_color": "blue",
              "vehicle_identification_number": "5J6YH43L801481",
              "vehicle_mileage": 10000,
              "vehicle_model": "Element",
              "vehicle_registration_certificate_file_number": "K20017708",
              "vehicle_registration_plate": "N21YWL",
              "vehicle_type_id": 0,
              "vehicle_year": "2003"
          },
          "from_zone_id": 0,
          "id": 41114968,
          "is_business": false,
          "issued_time": "2022-09-01T22:58:55",
          "json_data": "f088d5bd-0d7a-40af-a771-49b13d9f010f",
          "path": {
              "from_address": "Yuzu sushi, Boulevard Robert-Bourassa, Laval, QC, Canada",
              "from_latitude": 45.611028,
              "from_longitude": -73.700913,
              "path_pk": "1576876224....25361223....2618400790....iTbwqENZUZ0K3y/DuWrFMqulPHixGNmmciYNBgXyhNb9iqostJIQwEHgEnDdkuq9EntS/dN3YQBJsSXeN+3kVxZwi0N+qU5yxExfi8RGk27j4rrANRQwRtL0ypEpAobQyD/cmOoEEggXEuexcoPHgtGpz1dIT+2xGIKq34x9fNgPBjcymVK/HhBA9ZEVF1E/Fp6QF+jWTcXUtRHJEFfNQ9ID24Ix0SeLajzX+8kUaOMJzy7ExhG63H6tV29WY7okoWKHcpEvm4o3hfo+dqhXxyk12RDUMvRotNROhlOhnDKpgw/YyH8Xj4Op6SCX3piW1pSzxN73GTChgaE5BZRgDl6Ls8mhrSlhyZPENtOivLmPd/Pf9i1dzvsv9sximIUd",
              "rider_public_key": "EOS6MQmKaCQxqPjjFFGfhLdCP5bd9ZccANghjcaoh9BuuHLXvZ",
              "to_address": "2269 Rue Valiquette, Laval, QC, Canada",
              "to_latitude": 45.5854692,
              "to_longitude": -73.7209769
          },
          "path_pk": "1576876224....25361223....2618400790....iTbwqENZUZ0K3y/DuWrFMqulPHixGNmmciYNBgXyhNb9iqostJIQwEHgEnDdkuq9EntS/dN3YQBJsSXeN+3kVxZwi0N+qU5yxExfi8RGk27j4rrANRQwRtL0ypEpAobQyD/cmOoEEggXEuexcoPHgtGpz1dIT+2xGIKq34x9fNgPBjcymVK/HhBA9ZEVF1E/Fp6QF+jWTcXUtRHJEFfNQ9ID24Ix0SeLajzX+8kUaOMJzy7ExhG63H6tV29WY7okoWKHcpEvm4o3hfo+dqhXxyk12RDUMvRotNROhlOhnDKpgw/YyH8Xj4Op6SCX3piW1pSzxN73GTChgaE5BZRgDl6Ls8mhrSlhyZPENtOivLmPd/Pf9i1dzvsv9sximIUd",
          "prepaid_tip": 0,
          "ride_request_id": "f088-0d7a-40af-a771-49b13d9f010f",
          "ride_service_id": 1,
          "ride_service_id_object": null,
          "ride_service_type_id": 1,
          "rider": "rider",
          "rider_chain_account_object": null,
          "route": null,
          "start_time": null,
          "to_zone_id": 0,
          "toll_charge": 0,
          "tracking_id": null
      }
      });
      this.setDirections();
  }

  componentWillUnmount() {
    clearInterval(this.statusInterval);
    clearInterval(this.rideTrackingInterval);
    clearInterval(this.refreshDirectionsInterval);
    clearInterval(this.simulateETADecrease);
  }

  loadStyle = (trackingLinkStyle) => {
    if (typeof trackingLinkStyle === 'undefined' || trackingLinkStyle === null) {
      return null;
    }

    return {
      primaryColor: (trackingLinkStyle.primary_color) ? `rgb(${trackingLinkStyle.primary_color})` : 'rgb(255,192,2)',
      secondaryColor: (trackingLinkStyle.secondary_color) ? `rgb(${trackingLinkStyle.secondary_color})` : 'rgb(0,0,0)',
      headerColor: (trackingLinkStyle.header_color) ? `rgb(${trackingLinkStyle.header_color})` : 'rgb(255,192,2)',
      textColor: (trackingLinkStyle.text_color) ? `rgb(${trackingLinkStyle.text_color})` : 'rgb(0,0,0)',
      titleColor: (trackingLinkStyle.title_color) ? `rgb(${trackingLinkStyle.title_color})` : 'rgb(0,0,0)',
      backgroundColor: (trackingLinkStyle.background_color) ? `rgb(${trackingLinkStyle.background_color})` : 'rgb(255,255,255)',
      lineToPickup: (trackingLinkStyle.line_to_pickup_color) ? trackingLinkStyle.line_to_pickup_color.split(',').map(i => parseInt(i)) : [43, 179, 199],
      lineToDestination: (trackingLinkStyle.line_to_destination_color) ? trackingLinkStyle.line_to_destination_color.split(',').map(i => parseInt(i)) : [0, 81, 184],
      companyLogo: trackingLinkStyle.company_logo_b64,
      loadingIcon: trackingLinkStyle.loading_icon_b46,
      logoRedirect: trackingLinkStyle.logo_redirect || "https://goeva.com",
      carIcon: trackingLinkStyle.car_icon_b64,
      carIconNight: trackingLinkStyle.car_icon_night_b64,
      pickupPinIcon: trackingLinkStyle.pickup_icon_b64,
      pickupPinIconNight: trackingLinkStyle.pickup_icon_night_b64,
      destinationPinIcon: trackingLinkStyle.destination_icon_b64,
      destinationPinIconNight: trackingLinkStyle.destination_icon_night_b64,
      carIconWidth: trackingLinkStyle.car_icon_width || 47,
      carIconHeight: trackingLinkStyle.car_icon_height || 128,
      pickupPinIconWidth: trackingLinkStyle.pickup_icon_width || 128,
      pickupPinIconHeight: trackingLinkStyle.pickup_icon_height || 128,
      destinationPinIconWidth: trackingLinkStyle.destination_icon_width || 128,
      destinationPinIconHeight: trackingLinkStyle.destination_icon_height || 128,
      timeOfDay: trackingLinkStyle.timeOfDay || null,
      forceMapUpdate: trackingLinkStyle.forceMapUpdate || null
    }
  }

  checkStatus = async () => {
    // const services_address = this.state.community.services_address + "/";
    let requestStatus = await getRideRequestStatus(
      this.state.ride_request_id,
      //services_address
    );
    // console.log('requestStatus', requestStatus);

    const ride = await getActiveRide(this.state.tracking_id);

    // console.log('ride', ride);

    //Add a 5 minute buffer between NOT_YET_ACCEPTED and LIVE state.
    //This is to avoid situations where a driver accepts and cancels, confusing the customer.
    //Start
    if (ride && !(ride instanceof Error)) {
      const now = moment.utc();
      const acceptedTime = moment.utc(ride.accepted_time);

      if (moment.duration(now.diff(acceptedTime)).asMinutes() < DRIVER_ACCEPT_LIVE_DELAY) {
        requestStatus = RideRequestStatus.NOT_YET_ACCEPTED;
      }
    }
    //End

    if (requestStatus === this.state.requestStatus) {
      return;
    } else if (
      this.state.requestStatus === RideRequestStatus.LIVE &&
      requestStatus !== RideRequestStatus.LIVE
    ) {
      clearInterval(this.rideTrackingInterval);
      clearInterval(this.refreshDirectionsInterval);
      clearInterval(this.simulateETADecrease);
    }
    this.setState({ requestStatus });
    const that = this;
    switch (requestStatus) {
      case RideRequestStatus.INVALID:
        this.setState({ showInvalidLink: true });
        break;
      case RideRequestStatus.SCHEDULED_FOR_LATER:
        await this.setDirections();
        break;
      case RideRequestStatus.CANCELLED_BY_RIDER:
      case RideRequestStatus.CANCELLED_BY_DRIVER:
      case RideRequestStatus.CANCELLED_BY_COMMUNITY:
        // Temporary.  New task will be created to still show public tracking link for normal rides after they were
        // cancelled.  Right now this does not exist and the link shows as invalid if we refresh
        this.setState({ isRideCancelled: true, helpScreen: false });
      case RideRequestStatus.NO_DRIVER:
      case RideRequestStatus.NOT_YET_ACCEPTED:
        await this.setDirections();
        break;
      case RideRequestStatus.LIVE:
        await this.getStaticRideData(ride);
        await this.getDynamicRideData();
        await this.setDirections();

        this.rideTrackingInterval = setInterval(() => {
          that.getDynamicRideData();
        }, REFRESH_RATE_DYNAMIC_DATA_SECONDS * 1000);
        this.refreshDirectionsInterval = setInterval(async function () {
          that.setDirections();
        }, 1000 * (60 * REFRESH_RATE_MINUTES_DIRECTIONS));
        /* COMMENTING THIS PART SINCE IT ONLY SIMULATE A DECREASE IN ETA */
        /*this.simulateETADecrease = setInterval(async function () {
          that.setState({ eta: that.state.eta - 1 });
        }, 60000);*/
        break;
      case RideRequestStatus.SUCCESS:
        await this.getStaticRideData();
        if (this.state.isCompletedRideInDatabase) {
          await this.setDirections();
          await this.setState({ successDatetime: this.state.ride.end_time });
          if (this.state.is_business === false) this.showSuccessPopup();
        }
        break;
      case RideRequestStatus.LIVE_OTHER_STEP:
        await this.getStaticRideData();
        await this.getDynamicRideData();
        await this.setDirections();
        this.rideTrackingInterval = setInterval(() => {
          that.getDynamicRideData();
        }, REFRESH_RATE_DYNAMIC_DATA_SECONDS * 1000);
        this.refreshDirectionsInterval = setInterval(async function () {
          that.setDirections();
        }, 1000 * (60 * REFRESH_RATE_MINUTES_DIRECTIONS));
        /* COMMENTING THIS PART SINCE IT ONLY SIMULATE A DECREASE IN ETA */
        /*this.simulateETADecrease = setInterval(async function () {
          that.setState({ eta: that.state.eta - 1 });
        }, 60000);*/
        break;
      case RideRequestStatus.SUCCESS:
        await this.getStaticRideData();
        if (this.state.isCompletedRideInDatabase) {
          await this.setDirections();
          await this.setState({ successDatetime: this.state.ride.end_time });
          if (this.state.is_business === false) this.showSuccessPopup();
        }
        break;
      default:
        break;
    }
  };

  showSuccessPopup = () => {
    const popup = (
      <div className="live-tracker-overlay-black">
        <div className="live-tracker-success-popup">
          <img
            className="live-tracker-success-popup-close-btn"
            src={plusImg}
            onClick={() => this.setState({ successPopup: null })}
            alt="CLOSE"
          />
          <div className="live-tracker-success-popup-text">
            <Trans>SUCCESS_RIDE_POPUP</Trans>
            <div className="live-tracker-smiley-wrapper">
              <img className="live-tracker-smiley-img" src={smiley} alt="SMILEY" />
            </div>
          </div>
          <div className="live-tracker-success-popup-img">
            <img
              className="marketing-img"
              src={adImg}
              onClick={() =>
                window.open("https://goeva.com/#/download", "_blank")
              }
              alt="EVA_PHONES"
            />
            <div className="arrow-wrapper">
              <img
                className="live-tracker-arrow-img"
                src={arrowImg}
                onClick={() =>
                  window.open("https://goeva.com/#/download", "_blank")
                }
                alt="ARROW"
              />
            </div>
          </div>
        </div>
      </div>
    );
    this.setState({ successPopup: popup });
  };

  showProxyPopup = (proxyNumber, callerPhone) => {
    const callerPhoneFormatted = formatPhoneNumber(callerPhone);
    const hiddenNumber = callerPhoneFormatted.substring(0, callerPhoneFormatted.length - 4).replace(/\d/g, "X") + callerPhoneFormatted.substring(callerPhoneFormatted.length-4, callerPhoneFormatted.length);

    const popup = (
      <div className="live-tracker-overlay-black">
        <div className="live-tracker-proxy-popup">
          <img
            className="live-tracker-success-popup-close-btn"
            src={plusImg}
            onClick={() => this.setState({ proxyPopup: null })}
            alt="CLOSE"
          />
          <div className="live-tracker-proxy-popup-title">
            <Trans>BEFORE_WE_CONTINUE</Trans>
          </div>
          <Separator />
          <div className="live-tracker-proxy-popup-phone">
          {formatPhoneNumber(proxyNumber)}
          </div>
          <div className="live-tracker-proxy-popup-text-container">
            <div className="live-tracker-proxy-popup-text">
              <Trans>PROXY_PHONE_DISCLAIMER</Trans>{hiddenNumber}
            </div>
          </div>
            <div className="action-button" onClick={() => {}}>
              <div style={{ margin: "auto" }}>
                <Trans>CALL</Trans>
              </div>
            </div>
          </div>
      </div>
    );
    this.setState({ proxyPopup: popup });
  };

  getStaticRideData = async (ride) => {
    if (isUndefined(ride) || ride instanceof Error) {
      ride = await getCompletedRide(this.state.tracking_id);
      // console.log('GET COMP RIDE!!!!!')
    }
    if (isUndefined(ride) || ride instanceof Error) {
      this.setState({ isCompletedRideInDatabase: false });
    } else {
      const { driver_object, driver_profile_image_hash } = ride;
      const img_hash = driver_profile_image_hash || defaultProfileImgHash;
      //const driverImage = await getIPFSImgB64(img_hash, this.state.community.services_address + "/");
      const driverRating = parseInt(driver_object.rating_avgx100 / 100);
      this.setState({ driverRating });
      const driverProfile = ride.driver_object.profile;
      await this.setState({
        ride,
        driverProfile,
        //driverImage,
        driverRating,
        isCompletedRideInDatabase: true
      });
    }
  };

  getDynamicRideData = async () => {
    const ride = await getActiveRide(this.state.tracking_id);
    let nextDriverPosition;
    if (this.state.requestStatus != RideRequestStatus.LIVE_OTHER_STEP) {
      const position = await getDriverPosition(ride.driver_account);
      nextDriverPosition = [
        parseFloat(position.lon),
        parseFloat(position.lat),
      ];
    } else {
      nextDriverPosition = [0, 0];
    }
    const rideStatus = this.getRideStatus(
      ride.driver_onsite_time,
      ride.start_time
    );
    await this.setState({
      ride,
      rideStatus,
      nextDriverPosition,
    });
  };

  getRideStatus = (onsite_time, start_time) => {
    const onSite = onsite_time !== null,
      started = start_time !== null;
    if (this.state.requestStatus === RideRequestStatus.LIVE_OTHER_STEP) {
      return LiveRideStatus.OUT_FOR_DELIVERY;
    } else if (this.state.eta <= 2) {
      return LiveRideStatus.ARRIVED;
    } else if (started) {
      return LiveRideStatus.ON_WAY_TOWARDS_DESTINATION;
    } else if (onSite && !started) {
      return LiveRideStatus.PICKING_UP_PACKAGE;
    } else {
      return LiveRideStatus.ON_WAY_TOWARDS_PICKUP;
    }
  };

  changeLanguage = (key) => {
    if (key){
      i18n.changeLanguage(key);
      this.setState({currentLanguage:{'Key':key,'Label':key.toUpperCase()}}); 
    }
    this.setState({ key: Math.random() });
  };

  setDirections = async () => {
    const {
      nextDriverPosition,
      pickup,
      destination,
      rideStatus,
      requestStatus,
    } = this.state;
    let pickupDirections,
      eta = 0,
      timeToPickupLocation = 0,
      timeFromPickupLocationToDestination = 0;
    if (requestStatus === RideRequestStatus.LIVE) {
      if (
        rideStatus === LiveRideStatus.ON_WAY_TOWARDS_PICKUP ||
        rideStatus === LiveRideStatus.PICKING_UP_PACKAGE
      ) {
        const res = await getOSRMDirections([
          nextDriverPosition,
          pickup.coords,
        ]);
        pickupDirections = res.directions;
        timeToPickupLocation = res.timeEstimation + 2; //Slight overestimation to make the eta more accurate
        eta += timeToPickupLocation;
      }

      const directions = (
        await getOSRMDirections([pickup.coords, destination.coords])
      ).directions;
      const res = await getOSRMDirections([nextDriverPosition, destination.coords]);
      timeFromPickupLocationToDestination = res.timeEstimation + 1; //Slight overestimation to make the eta more accurate
      eta += timeFromPickupLocationToDestination;
      this.setState({ pickupDirections, directions, eta, timeToPickupLocation, timeFromPickupLocationToDestination });
    } else {
      const directions = (
        await getOSRMDirections([pickup.coords, destination.coords])
      ).directions;
      eta = "-";
      this.setState({ directions, eta, timeToPickupLocation, timeFromPickupLocationToDestination });
    }
  };

  showHideHelp = () => {
    this.setState({ helpScreen: !this.state.helpScreen });
  };

  minimizeDrawerMobile = () => {
    this.setState({ minimizeDrawer: !this.state.minimizeDrawer });
  };

  openLanguageDropdown() {
    // console.log('showLanguageDropdown')
      this.setState({ showLanguageDropdown: !this.state.showLanguageDropdown });
  }

  closeLanguageDropdown() {
    // console.log('closeLanguageDropdown')
      this.setState({ showLanguageDropdown: false });
  }

  render() {
    const customMessage = this.state.customCommMessage && this.state.customCommMessage.message.find(e => e.lang === i18n.language);
    const customMessageSeverity = this.state.customCommMessage && this.state.customCommMessage.severity;
    if (isMobile()) {
      return this.state.loading ? (
        <div style={{ height: "100vh" }}>
          <LoadingIcon icon={this.state.loadingIcon} />
        </div>
      ) : (
        <div className="live-tracker-container" key={this.state.key}>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/jhk2jdy.css"
          ></link>
          {this.state.successPopup}
          {this.state.proxyPopup}
          {this.state.helpScreen ? <HelpScreen isBusiness={this.state.is_business}
            isMiaRide={this.state.isMiaRide}
            rideServiceTypeId={this.state.ride_service_type_id}
            trackingId={this.state.tracking_id}
            goBackFunc={this.showHideHelp}
            isPublic={this.props.public}
            style={this.state.style} /> : null}

          <div className="live-tracker-header" style={{ backgroundColor: this.state.style && this.state.style.headerColor }}>
            <a className="logo-link" href={this.state.style ? this.state.style.logoRedirect : "https://goeva.com/"} target="_blank" rel="noopener noreferrer">
              <img src={this.state.evaLogo} alt="LOGO" />
            </a>
            <span
              className="live-tracker-header-menu-btn"
              id="help-btn"
              onClick={() => this.showHideHelp()}
              style={{ color: this.state.style && this.state.style.secondaryColor }}
            >
              <Trans>HELP</Trans>
            </span>
            <div
              className="live-tracker-header-menu-btn"
              onClick={() => this.openLanguageDropdown()}
              style={{ color: this.state.style && this.state.style.secondaryColor, backgroundColor: this.state.style && this.state.style.primaryColor}}
              onBlur={() => this.closeLanguageDropdown()}
            >
             <div style={{textTransform: 'capitalize', backgroundColor: this.state.style && this.state.style.headerColor}}><Trans>{this.state.currentLanguage.Label}</Trans></div>
              {this.state.showLanguageDropdown &&
                  <div className="options" style={{backgroundColor: this.state.style && this.state.style.backgroundColor, color: this.state.style && this.state.style.textColor}}>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('fr');}}
                      >
                          Français
                      </div>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('en'); }}
                      >
                          English
                      </div>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('es'); }}
                      >
                          Español
                      </div>
                  </div>
              }
            </div>
          </div>
          {this.state.showInvalidLink ? (
            <Invalid style={this.state.style} />
          ) : (
            <div style={{ height: "100%" }}>
            {customMessage && customMessage.text && this.state.showInvalidLink !== true && this.state.isCompletedRideInDatabase !== true && !(this.props.showDemo) &&
            <div className='live-tracker-custom-toast' style={{backgroundColor: customMessageSeverity === 'INFO' ? "rgb(154, 179, 140)" : customMessageSeverity === 'WARN' ? "rgb(239, 190, 46)" : "rgb(222, 12, 55)"}}>
              {customMessage.text}
            </div>
           }

              <div className="map">
                <Map
                  pickup={this.state.pickup}
                  destination={this.state.destination}
                  nextDriverPosition={this.state.nextDriverPosition || [0, 0]}
                  directions={this.state.directions}
                  pickupDirections={this.state.pickupDirections}
                  style={this.state.style}
                />
              </div>
              <div className={"live-tracker-drawer" + ((this.state.minimizeDrawer) ? " minimized" : "")} style={{backgroundColor: this.state.style && this.state.style.backgroundColor || "white"}}>
                <button type="button" className="reduce-drawer-live-tracker-btn" style={{backgroundColor: this.state.style && this.state.style.backgroundColor || "white"}} onClick={() => this.minimizeDrawerMobile()}>
                  <img src={grayArrowImg} alt="ARROW" className={"reduce-drawer-live-tracker" + ((this.state.minimizeDrawer) ? " minimized-reducer" : "")} style={{filter: (this.state.style && this.state.style.textColor) ? `drop-shadow(0 0 0 ${this.state.style.textColor}) drop-shadow(0 0 0 ${this.state.style.textColor})` : ""}} />
                </button>
                <Drawer
                showProxyPopup={this.showProxyPopup}
                  isBusiness={this.state.is_business}
                  isPublic={this.props.public}
                  rideServiceTypeId={this.state.ride_service_type_id}
                  trackingId={this.state.tracking_id}
                  callerPhone={(this.state.is_business) ? this.state.businessPhone : this.state.customerPhone}
                  expectedDeliveredByDatetime={this.state.expectedDeliveredByDatetime}
                  scheduledDatetime={this.state.scheduledDatetime}
                  pickup={this.state.pickup}
                  destination={this.state.destination}
                  note={this.state.note}
                  eta={this.state.eta}
                  timeToPickupLocation={this.state.timeToPickupLocation}
                  timeFromPickupLocationToDestination={this.state.timeFromPickupLocationToDestination}
                  status={this.state.rideStatus}
                  requestStatus={this.state.requestStatus}
                  activeRide={this.state.ride}
                  driverProfile={this.state.driverProfile}
                  driverImage={this.state.driverImage}
                  successDatetime={this.state.successDatetime}
                  style={this.state.style}
                  isMiaRide={this.state.isMiaRide}
                  isRideCancelled={this.state.isRideCancelled}
                />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return this.state.loading ? (
        <LoadingIcon icon={this.state.loadingIcon} />
      ) : (
        <div className="live-tracker-container" key={this.state.key}>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/jhk2jdy.css"
          ></link>
          {this.state.successPopup}
          {this.state.proxyPopup}
          {customMessage && customMessage.text && this.state.showInvalidLink !== true && this.state.isCompletedRideInDatabase !== true && !(this.props.showDemo) &&
            <div className='live-tracker-custom-toast' style={{backgroundColor: customMessageSeverity === 'INFO' ? "rgb(154, 179, 140)" : customMessageSeverity === 'WARN' ? "rgb(239, 190, 46)" : "rgb(222, 12, 55)"}}>
              {customMessage.text}
            </div>
           }

          <div className="live-tracker-header" style={{ backgroundColor: this.state.style && this.state.style.headerColor }}>
            <a className="logo-link" href={this.state.style ? this.state.style.logoRedirect : "https://goeva.com"} target="_blank" rel="noopener noreferrer">
              <img src={this.state.evaLogo} alt="LOGO" />
            </a>
            <div
              className="live-tracker-header-menu-btn"
              onClick={() => this.openLanguageDropdown()}
              style={{ color: this.state.style && this.state.style.secondaryColor, backgroundColor: this.state.style && this.state.style.primaryColor}}
              onBlur={() => this.closeLanguageDropdown()}
            >
             <div style={{textTransform: 'capitalize', backgroundColor: this.state.style && this.state.style.headerColor}}><Trans>{this.state.currentLanguage.Label}</Trans></div>
              {this.state.showLanguageDropdown &&
                  <div className="options" style={{backgroundColor: this.state.style && this.state.style.backgroundColor, color: this.state.style && this.state.style.textColor}}>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('fr');}}
                      >
                          Français
                      </div>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('en'); }}
                      >
                          English
                      </div>
                      <div
                          onClick={_ => {this.closeLanguageDropdown(); this.changeLanguage('es'); }}
                      >
                          Español
                      </div>
                  </div>
              }
            </div>
          </div>
          {this.state.showInvalidLink ? (
            <Invalid showDemo={this.props.showDemo} style={this.state.style} />
          ) : (
            <div style={{ height: "100%" }}>
              <div className="map">
                <Map
                  pickup={this.state.pickup}
                  destination={this.state.destination}
                  nextDriverPosition={this.state.nextDriverPosition || [0, 0]}
                  directions={this.state.directions}
                  pickupDirections={this.state.pickupDirections}
                  style={this.state.style}
                  showDemo={this.props.showDemo}
                />
              </div>
              <div className="live-tracker-drawer" style={{backgroundColor: this.state.style && this.state.style.backgroundColor || "white"}}>
                <Drawer
                  showProxyPopup={this.showProxyPopup}
                  isBusiness={this.state.is_business}
                  isPublic={this.props.public}
                  rideServiceTypeId={this.state.ride_service_type_id}
                  trackingId={this.state.tracking_id}
                  expectedDeliveredByDatetime={this.state.expectedDeliveredByDatetime}
                  scheduledDatetime={this.state.scheduledDatetime}
                  pickup={this.state.pickup}
                  destination={this.state.destination}
                  note={this.state.note}
                  eta={this.state.eta}
                  timeToPickupLocation={this.state.timeToPickupLocation}
                  timeFromPickupLocationToDestination={this.state.timeFromPickupLocationToDestination}
                  status={this.state.rideStatus}
                  callerPhone={(this.state.is_business) ? this.state.businessPhone : this.state.customerPhone}
                  requestStatus={this.state.requestStatus}
                  activeRide={this.state.ride}
                  driverProfile={this.state.driverProfile}
                  driverImage={this.state.driverImage}
                  successDatetime={this.state.successDatetime}
                  style={this.state.style}
                  isMiaRide={this.state.isMiaRide}
                  isRideCancelled={this.state.isRideCancelled}
                  showDemo={this.props.showDemo}
                />
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}

export default LiveTracker;
