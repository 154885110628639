import { apiAddress } from "../variables/secretEnvVariables";
import axios from "axios";
import { logout } from "../actions/index";
import { store } from "../store/index";
import { isEmptyObject, removeAccents } from "../views/util";

const API_VERSION_1 = "";
const API_VERSION_2 = "api/v2/";
const CAPTCHA_TOKEN = process.env.REACT_APP_CAPCHA_TOKEN;

const encodeGetParams = (p) =>
  Object.entries(p)
    .map((kv) => kv.map(encodeURIComponent).join("="))
    .join("&");

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});



export function getSelectedCommunity() {
  // return storeState.userData.partnerName
  if (!storeState.userData.communitiesAuthList) return null;

  return storeState.userData.communitiesAuthList
   .filter(auth => removeAccents(auth.communityName) == storeState.userData.selectedCommunityName)[0];
}

export function getRiderAccount() {
  return getSelectedCommunity().riderAccount;
}

export function sendLoggedGET(endpoint, ignore404 = false, apiVers = API_VERSION_1, options = {},) {
  console.log('sendLoggedGET', endpoint, ignore404, apiVers, options)
  const url=apiAddress + apiVers + endpoint;
  return axios
    .get(url, {
      params: {},
      headers: {
        'accept': 'application/json',
        'CaptchaToken': CAPTCHA_TOKEN,
        'Content-Type': 'application/json',
        'Authorization': storeState.userData.userAuthKey
    },
      ...options
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error)
      if (error.response.status === 401) {
        // store.dispatch(logout());
      } else if (error.response.status === 404) {
        if (!ignore404) {
          window.location.href = "/not_found";
          return;
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    });
}

export function sendLoggedGETPublic(endpoint, ignore404 = false, apiVers = API_VERSION_1) {
  console.log('sendLoggedGETPublic', endpoint, ignore404, apiVers)
  /*const url = apiAddress + apiVers + endpoint;
  return axios
    .get(url, {
      params: {},
      timeout: 8000,
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
      if (error.response.status === 401) {
        store.dispatch(logout());
      } else if (error.response.status === 404) {
        if (!ignore404) {
          window.location.href = "/not_found";
          return;
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    });*/
}

export async function sendLoggedPOST(endpoint, body, apiVers = API_VERSION_1) {
  const url = apiAddress + apiVers + endpoint;
  // console.log('sendLoggedPOST', url, body, apiVers);
  
  return axios.post(url, body, {
    params: {},
    headers: {
      'accept': 'application/json',
      'CaptchaToken': CAPTCHA_TOKEN,
      'Content-Type': 'application/json',
      'Authorization': storeState.userData.userAuthKey
    },
  }).then(result => {
      return result;
  }).catch(error => {
      if (error.response.status === 401) {
          store.dispatch(logout());
      } else if (error.response.status === 404) {
          window.location.href = "/not_found";
          return;
      } else {
          throw error;
      }
  });
}

export function sendLoggedPUT(endpoint, body, apiVers = API_VERSION_1) {
  console.log('sendLoggedPUT', endpoint, body, apiVers)
  /*const url = apiAddress + apiVers + endpoint;
  return axios.put(url, body, {
    params: {},
    headers: {
      Authorization: getSelectedCommunity() ? getSelectedCommunity().token : null,
    }
  }).then(result => {
      return result;
  }).catch(error => {
      console.log('error', error);
      if (error.response.status === 401) {
          store.dispatch(logout());
      } else if (error.response.status === 404) {
          window.location.href = "/not_found";
          return;
      } else {
          throw error;
      }
  });*/
}

export function sendLoggedDELETE(endpoint, body, apiVers = API_VERSION_1) {
  /*const url = apiAddress + apiVers + endpoint;
  return axios.delete(url, {
    data: body,
    headers: {
      Authorization: getSelectedCommunity() ? getSelectedCommunity().token : null,
    }
  }).then(result => {
    console.log(result)
      return result;
  }).catch(error => {
      console.log('error', error);
      if (error.response.status === 401) {
          store.dispatch(logout());
      } else if (error.response.status === 404) {
          window.location.href = "/not_found";
          return;
      } else {
          throw error;
      }
  });*/
}

export function signin(email, password) {
  const endpoint = apiAddress + "signin_mia";
  let success = false;
  let token = null;
  let riderAccount = null;
  let companyName = null;
  return axios
    .post(endpoint, { email, password })
    .then((response) => {
      success = response.data.success;
      token = response.data.token;
      companyName = response.data.company_name;
      riderAccount = response.data.rider_account;
      return { success, token, riderAccount, companyName };
    })
    .catch((error) => {
      return { success, token, riderAccount, companyName };
    });
}

export function auth() {
  return sendLoggedGET("auth")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getDriverPosition(driver, url) {
  const params = encodeGetParams({
    driver: driver,
  });
  return sendLoggedGETPublic("get_driver_last_location?" + params, false, undefined, url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getDeliveryProof(tracking_id) {
  return sendLoggedGET(`rides/${tracking_id}/delivery-proof`, true, API_VERSION_2, { responseType: "blob" });
}

export function getDeliveryProofExistence(tracking_id) {
  return sendLoggedGET(`rides/${tracking_id}/delivery-proof?exist=true`, true, API_VERSION_2)
    .then(response => {
      console.log(response.data.exists)
      return response.data.exists
    })
    .catch(error => false);
}

export function getActiveRide(tracking_id, is_from_mia, ignore404 = false, url) {
  const params = encodeGetParams({
    tracking_id: tracking_id,
    is_from_mia: is_from_mia
  });
  return sendLoggedGETPublic("get_active_ride?" + params, ignore404, API_VERSION_1, url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function getTrackingObj(tracking_id, communities) {
  const params = encodeGetParams({ tracking_id });
  const promises = communities.map(community => sendLoggedGETPublic("get_tracking_obj?" + params, true, API_VERSION_1, community.services_address + "/"));

  const settledPromises = await Promise.allSettled(promises);

  const resolvedPromises = settledPromises
    .filter(result => result.status === 'fulfilled' && !isEmptyObject(result.value.data))
    .map(result => result.value);

  if (resolvedPromises.length === 0) {
    return {community:null, tracking_obj: {ride_request_id: false}};
  }
  return {community: findCommunityByBaseUrl(communities, getBaseUrl(resolvedPromises[0].request.responseURL)), tracking_obj: resolvedPromises[0].data};
}

export async function getCompletedTrackingObj(tracking_id, communities) {
  const params = encodeGetParams({ tracking_id });

  const promises = communities.map(community => sendLoggedGETPublic("get_completed_tracking_obj?" + params, true, API_VERSION_1, community.services_address + "/"));

  const settledPromises = await Promise.allSettled(promises);

  const resolvedPromises = settledPromises
    .filter(result => result.status === 'fulfilled' && !isEmptyObject(result.value.data))
    .map(result => result.value);

  if (resolvedPromises.length === 0) {
    return {community:null, tracking_obj: {ride_request_id: false}};
  }
  return {community: findCommunityByBaseUrl(communities, getBaseUrl(resolvedPromises[0].request.responseURL)), tracking_obj: resolvedPromises[0].data};
}

export function getCorporateRides(data) {
  const params = encodeGetParams({
    rider: getSelectedCommunity().riderAccount,
    ...data
  });
  return sendLoggedGET("get_corporate_rides?" + params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

/*export function getCompletedCorporateRides(data) {
  console.log(storeState.userData)
  const params = encodeGetParams({
    rider: getSelectedCommunity().riderAccount,
    ...data
  });
  return sendLoggedGET("get_completed_corporate_rides?" + params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}*/

export function getIPFSImgB64(hash, url) {
  const params = encodeGetParams({
    hash: hash,
  });
  return sendLoggedGETPublic("get_ipfs_image_b64?" + params, false, undefined, url)
    .then((result) => {
      return result.data.replace('"', "");
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getRideRequestStatus(ride_request_id, url) {
  const params = encodeGetParams({
    ride_request_id: ride_request_id,
  });
  return sendLoggedGETPublic("get_ride_request_status?" + params, true, API_VERSION_1, url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function reportIssue(
  tracking_id,
  name,
  email,
  phone,
  message_code,
  message,
  is_business,
  is_mia_ride
) {
  const body = {
    tracking_id,
    name,
    email,
    phone,
    message_code,
    message,
    is_business,
    is_mia_ride
  };
  return await sendLoggedPOST("api/v1/mia/report_issue", body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function getAddressAutoComplete(lat, lon, query) {
  const params = encodeGetParams({
    lat: lat,
    lon: lon,
    query: query,
  });
  return sendLoggedGETPublic("get_address_autocomplete?" + params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function getAddressDetails(address) {
  const params = encodeGetParams({
    address: address,
  });
  return await sendLoggedGETPublic("get_address_details?" + params)
    .then((result) => {
      return result.data["results"][0]["geometry"]["location"];
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function isServiceAvailable(
  pickup_latitude,
  pickup_longitude,
  ride_service_id
) {
  const params = encodeGetParams({
    pickup_latitude: pickup_latitude,
    pickup_longitude: pickup_longitude,
    ride_service_id: ride_service_id,
  });

  return await sendLoggedGET("is_service_available?" + params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function reverseGeo(lat, lon) {
  const params = encodeGetParams({
    lat: lat,
    lon: lon,
  });
  return await sendLoggedGETPublic("reverse_geo?" + params)
    .then((result) => {
      return result.data["results"][0];
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export async function callRide(
  fromAddress,
  toAddress,
  fromCoords,
  toCoords,
  ride_service_type_id,
  first_name,
  last_name,
  email,
  phone,
  onsiteDatetime,
  orderReferenceNumber,
  pickupCompanyName,
  pickUpPhone,
  tipGivenAmountToken,
  note,
  isDeliveryFeePaidInCashAtDoor,
  cashPaymentOrderValue,
  fromApartmentNumber,
  toApartmentNumber
) {
  first_name = first_name || "---"
  last_name = last_name || "---"
  email = email || 'moreply@goeva.com'

  const addAptNum = (a, n) =>
    a.slice(0, a.indexOf(",")) + ` #${n}` + a.slice(a.indexOf(","));
  if (fromApartmentNumber)
    fromAddress = addAptNum(fromAddress, fromApartmentNumber);
  if (toApartmentNumber) toAddress = addAptNum(toAddress, toApartmentNumber);
  console.log('cashPaymentOrderValue', cashPaymentOrderValue);
  const totalOrderAmount = cashPaymentOrderValue ? cashPaymentOrderValue : 0;
  const pay_at_door = true ? cashPaymentOrderValue > 0 : false;
  console.log('isDeliveryFeePaidInCashAtDoor', isDeliveryFeePaidInCashAtDoor);

  const body = {
    from_latitude: fromCoords["lat"],
    from_longitude: fromCoords["lng"],
    to_latitude: toCoords["lat"],
    to_longitude: toCoords["lng"],
    from_address: fromAddress,
    to_address: toAddress,
    customer_first_name: first_name,
    customer_last_name: last_name,
    customer_phone: phone,
    customer_email: email,
    ride_service_type_id: ride_service_type_id,
    onsite_datetime: onsiteDatetime,
    order_number: orderReferenceNumber,
    pick_up_company_name: pickupCompanyName,
    pick_up_company_phone: pickUpPhone,
    tip_token_amount: tipGivenAmountToken,
    //commenting out due to removal of dispatch_method from V2 API implementation
    //dispatch_method: dispatchProtocolMethods.BY_ONSITE_TIME_V2,
    note: note,
    total_order_amount: totalOrderAmount,
    pay_at_door,
    pay_at_door_include_delivery_fee: isDeliveryFeePaidInCashAtDoor
  };

  if (phone.length <= 1) {
    delete body['customer_phone'] }

  if (!pickupCompanyName || pickupCompanyName.length <= 1) {
    delete body['pick_up_company_name'] }
  

  if (!pickUpPhone || pickUpPhone.length <= 1) {
    delete body['pick_up_company_phone'] }

  return sendLoggedPOST('rides/', body, API_VERSION_2)
    .then((result) => {
      const success = true;
      const data = result.data;
      return { data, success };
    })
    .catch((error) => {
      console.error(error);
      const success = false;
      const data = error;
      return { success, data };
    });
}

export async function cancelRide(tracking_id) {
  const body = {
    tracking_id: tracking_id,
  };
  return sendLoggedPOST("cancel_ride_mia", body)
    .then((result) => {
      const success = true;
      const data = result.data;
      return { data, success };
    })
    .catch((error) => {
      console.error(error);
      const success = false;
      const data = error;
      return { success, data };
    });
}

export async function getAccountSettings() {
  return await sendLoggedPOST("get_account_settings")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function saveAccountSettings(
  email,
  sendSmsTracking,
  sendEmailTracking,
  defaultAddress,
  aptNumber
) {
  const body = {
    email: email,
    send_sms_tracking: sendSmsTracking,
    send_email_tracking: sendEmailTracking,
    default_address: defaultAddress,
    apt_number: aptNumber,
  };
  return await sendLoggedPOST("update_account_settings", body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function getRideServicesByType() {
  return await sendLoggedGET("get_ride_services_by_type")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getCompletedRide(tracking_id, is_from_mia, ignore404 = false, url) {
  const params = encodeGetParams({ tracking_id, is_from_mia });
  return sendLoggedGET("get_completed_ride_public?" + params, ignore404, API_VERSION_1, {}, url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getTrackingStyle(rider, alias) {
  const params = encodeGetParams({ rider, alias });
  return sendLoggedGET("get_tracking_style?" + params)
  .then((result) => {
    return result.data;
  })
  .catch((error) => {
    console.error(error);
    return error;
  });
}

export function getCommunitySettings(code, url) {
  const body = { code };
  return sendLoggedPOST("get_community_settings_mia", body, API_VERSION_1, url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getExchangeRates(currency_code, apiVers = API_VERSION_2) {
  return sendLoggedGET("exchangerates/" + currency_code, false, apiVers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function onfleetImportRides(formData, apiVers = API_VERSION_2) {
  return sendLoggedPOST("rides/onfleet_import_rides", formData, apiVers)
  .then((result) => {
    return result.data;
  })
  .catch((error) => {
    console.error(error);
    return error;
  });
}

export function saveTrackingStyle(id, body, apiVers = API_VERSION_2) {
  Object.keys(body).forEach((key) => {
    if (typeof body[key] === 'undefined' || body[key] === null) {
      delete body[key];
    }
  })
  if (typeof id === 'undefined' || id === null) {
    return sendLoggedPOST("partners/styles/tracking", body, apiVers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
  } else {
    return sendLoggedPUT(`partners/styles/tracking/${id}`, body, apiVers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
  }
}

export function deleteTrackingStyle(id, apiVers = API_VERSION_2) {
  return sendLoggedDELETE(`partners/styles/tracking/${id}`, {}, apiVers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getTrackingStyles(id, apiVers = API_VERSION_2) {
  const route = (id !== undefined && id !== null) ? `partners/styles/tracking/${id}` : `partners/styles/tracking`;
  return sendLoggedGET(route, false, apiVers)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
}

export function getTrackingStylesPublic(account, apiVers = API_VERSION_2, url) {
  const route = `partners/public/styles/tracking?account=${account}`;
  return sendLoggedGETPublic(route, true, apiVers, url)
  .then((result) => {
    return result.data;
  })
  .catch((error) => {
    console.error(error);
    return error;
  });
}

export function getInvoices() {
  return sendLoggedGET(`api/v1/mia/invoices`, false).then(result => {
      return result.data;
  }).catch(error => {
      console.error(error);
      throw error;
  });
}

export function getPaymentPermissions() {
  return sendLoggedGET(`api/v1/mia/check_payment_method`, false).then(result => { 
    return result.data;
  }).catch(error => {
      console.error(error);
      throw error;
  });
}

function findCommunityByBaseUrl(communities, baseUrl) {
  // Loop through the array of communities
  for (const community of communities) {
    // Check if the 'services_address' property matches the baseUrl
    if (community.services_address === baseUrl) {
      return community; // Found the correct community
    }
  }
  return null; // Community not found
}

function getBaseUrl(fullUrl) {
  try {
    const url = new URL(fullUrl);
    const baseUrl = `${url.protocol}//${url.host}`;
    return baseUrl;
  } catch (error) {
    // Handle invalid URL
    throw new Error('Invalid URL');
  }
}
