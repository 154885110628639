import React, { useEffect, useState } from "react";
import FileUpload from "./components/FileUpload";
import AutoComplete from "../OrderRide/AutoComplete"; //TODO - Move to shared components folder
import i18n from "../../i18n";
import { Redirect } from "react-router-dom";
import {
  getAddressAutoComplete,
  getAccountSettings,
  onfleetImportRides,
  getAddressDetails,
} from "../../helpers/communityApiHelper";
import {
  getErrorAlert,
  getLoadingAlert,
  getSuccessAlert,
} from "../../helpers/alertHelper";
import "./BulkRideUpload.css";

function BulkRideUpload() {
  const [alert, setAlert] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);
  const [startLocation, setStartLocation] = useState(null);
  const [startLocationOptionalInfo, setStartLocationOptionalInfo] =
    useState(null);
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    getAccountSettings()
      .then((data) => {
        setStartLocation(data.default_address);
        setStartLocationOptionalInfo(data.apt_number);
      })
      .catch((e) => {
        //If the account settings call fails to populate the default address, do nothing.
      });

    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentLatitude(position.coords.latitude);
      setCurrentLongitude(position.coords.longitude);
    });
  }, []);

  const handleChangeStartLocation = (e) => {
    const startLocation = e.target.value;
    setAddressAutoCompleteResults(startLocation);
    setStartLocation(startLocation);
  };

  let timeout;
  const setAddressAutoCompleteResults = (query) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    if (!query) {
      setAutoCompleteResults([]);
      return;
    }

    timeout = setTimeout(() => {
      getAddressAutoComplete(currentLatitude, currentLongitude, query)
        .then((data) => {
          setAutoCompleteResults(data.predictions);
        })
        .catch((e) => {
          //If this call fails, do nothing. Not a blocker.
        });
    }, 250);
  };

  const handleStartLocationAutoCompleteSelect = (e) => {
    setAutoCompleteResults([]);
    setStartLocation(e.textContent);
  };

  const renameFile = (originalFile) => {
    const newNameWithExtension =
      "file-" +
      (Math.random() + 1).toString(36).substring(9) +
      "." +
      originalFile.name.split(".").pop();
    return new File([originalFile], newNameWithExtension, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const submit = async () => {
    setAlert(getLoadingAlert());
    try {
      const address =
        startLocation + (startLocationOptionalInfo
          ? "#" + startLocationOptionalInfo
          : "");
      const { lat, lng } = await getAddressDetails(address);

      const formData = new FormData();

      formData.append("file", renameFile(file));
      formData.append("from_address", address);
      formData.append("from_lat", lat);
      formData.append("from_lon", lng);

      const response = await onfleetImportRides(formData);

      if (response.success === true) {
        setAlert(
          getSuccessAlert(
            () => setRedirect(true),
            i18n.t("IMPORT_ORDERS_SUCCESS_TITLE"),
            ""
          )
        );
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      if (error instanceof Error) {
        error = error.message;
      }
      setAlert(getErrorAlert(() => hideAlert(), i18n.t("ERROR"), error));
    }
  };

  return (
    <div className="import-orders-form-container">
      {alert}
      {redirect && <Redirect to="/live_tracker_list" />}
      <div className="import-orders-form-wrapper">
        <div>
          <form autocomplete="off">
            <p className="title">{i18n.t("START_LOCATION")}</p>
            <div className="title-line" />
            <input
              id="startLocation"
              name="startLocation"
              placeholder={i18n.t("ADDRESS_PLACEHOLDER")}
              className="input-form"
              value={startLocation}
              onChange={handleChangeStartLocation}
            />
            <div className="autocomplete-results">
              <AutoComplete
                options={autoCompleteResults}
                idKey={"place_id"}
                valueKey={"description"}
                showOptions={true}
                handleClick={handleStartLocationAutoCompleteSelect}
              />
            </div>
            <input
              name="startLocationOptionalInfo"
              placeholder={i18n.t("OPTIONAL")}
              className="input-form"
              value={startLocationOptionalInfo}
              onChange={(e) => setStartLocationOptionalInfo(e.target.value)}
            />
          </form>
        </div>
        <p className="title">{i18n.t("ROUTE_FILE")}</p>
        <div className="title-line" />
        <div className="file-upload-container">
          <FileUpload handleFileUpload={(file) => setFile(file)} />
        </div>
        <button
          className="confirm-button"
          style={{ backgroundColor: file && startLocation ? "" : "grey" }}
          onClick={() => submit()}
          disabled={!file || !startLocation}
        >
          <p className="confirm-button-text">{i18n.t("SUBMIT")}</p>
        </button>
      </div>
    </div>
  );
}

export default BulkRideUpload;
