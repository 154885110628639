import * as actionTypes from "../../actions/action_types";

import { MenuItem, Nav, NavDropdown, NavItem } from "react-bootstrap";

import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { logout } from "../../actions";
import { store } from "../../store/index";
import Dropdown from "../DropDown/DropDown";
import "./HeaderLinks.css";

let storeState = store.getState();
store.subscribe(() => {
  storeState = store.getState();
});

class HeaderLinks extends React.Component {
  logout = () => {
    this.props.onInputIsAuth();
    const currentURL = window.location.href.split('?')[0];
    window.history.replaceState({}, document.title, currentURL);
    window.location.reload();
    store.dispatch(logout({}));
  };
  render() {
    const displayLang = this.props.currentLanguage;

    const logout = this.props.public ? null : (
      <NavDropdown
        eventKey={1}
        title={
          <div>
            <i className="fa fa-list" />
            <p className="hidden-md hidden-lg">
              More
              <b className="caret" />
            </p>
          </div>
        }
        noCaret
        id="basic-nav-dropdown-3"
        bsClass="dropdown-with-icons dropdown"
      >
        <MenuItem eventKey={1.1} onClick={() => this.logout()}>
          <div className="text-danger">
            <i className="pe-7s-close-circle" />
            <Trans>LOGOUT</Trans>
          </div>
        </MenuItem>
      </NavDropdown>
    );
    return (
      <div>
        <Nav pullRight className="custom-navbar">
          <Dropdown/>
          <NavDropdown
            title={
              <div>
                {displayLang}
                <p className="hidden-md hidden-lg">
                  Language
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-1"
          >
            <MenuItem onClick={()=> this.props.changeLanguage('fr')}>Français</MenuItem>
            <MenuItem onClick={()=> this.props.changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={()=> this.props.changeLanguage('es')}>Español</MenuItem>
          </NavDropdown>
          {logout}
        </Nav>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInputIsAuth: () =>
      dispatch({ type: actionTypes.UPDATE_ISAUTHENTICATED, value: false }),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.userData.isAuthenticated,
    communitiesAuthList: state.userData.communitiesAuthList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);

