import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import indexRoutes from "./routes/index.jsx";
//import FetchCommunityStrategy from "./strategies/FetchCommunityStrategy";
import { connect } from "react-redux";
import LoginPage from "./views/Login/LoginPage";
import { removeAccents } from "./views/util.js";
import LiveTrackerPublic from "./views/LiveTracker/LiveTrackerPublic";

class AppComponent extends Component {
  state = {
    communities: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
  }

  getParams() {
    // get the query string from the URL
    const queryString = window.location.search;
    // parse the query string to get the params
    const params = new URLSearchParams(queryString);
    return params;
  }

  render() {
    const { communities, error } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    let mainComponents = [];
    let isPublic = window.location.href.split('/')[3] === 'public';
    if (!isPublic) {
      //this.props.isAuthenticated = true
      if (this.props.isAuthenticated) {
        mainComponents = [
          //<FetchCommunityStrategy />,
          //<FetchExchangeRatesStrategy />,
          <BrowserRouter>
            <Switch>
              {indexRoutes.mia.map((prop, key) => {
                return (
                  <Route path={prop.path} component={prop.component} key={key} />
                );
              })}
            </Switch>
          </BrowserRouter>
        ];
      } else {
        console.log('In the else...')
        let params = this.getParams();
        let token, company_name, rider_account, error;
        let communitiesAuthList = null;
      
        mainComponents = [<LoginPage token={token} company_name={company_name} rider_account={rider_account}  error={error} communities={communities}/>];
        if (params){



          // Login URI information for external login Microsoft
          const token = params.get('token');
          console.log('params', params);
          console.log('context_ssob64', token);
          const tracking_id = params.get("tracking_id");
          console.log('tracking_id', tracking_id);

          if (tracking_id) {
            // Render the LiveTrackerPublic component with the tracking_id
            mainComponents = [
              <LiveTrackerPublic tracking_id={tracking_id} />
            ];
          } else if (token){

            const user_id = params.get('user_id');
            const partner_name = params.get('partner_name');
            mainComponents = [<LoginPage token={token} partner_name={partner_name}  />];
            const currentURL = window.location.href.split('?')[0];
            window.history.replaceState({}, document.title, currentURL);
            window.location.reload();
            //const context = JSON.parse(atob(context_ssob64))
            //if (context.length > 0) {
            /*  communitiesAuthList = [];
              for (var i = 0; i < context.length; i++){
                communitiesAuthList.push({
                  'token': context[i].auth_token,
                  'riderAccount': context[i].rider_account,
                  'companyName': context[i].company_name,
                  'community_services_url': context[i].services_address,
                  'communityName': removeAccents(context[i].community),
                  'communityAccount': context[i].community_account
                });
              }
              company_name = context[0].company_name;
              rider_account = context[0].rider_account;
              const currentURL = window.location.href.split('?')[0];
              window.history.replaceState({}, document.title, currentURL);
              window.location.reload();
              mainComponents = [<LoginPage token={context[0].auth_token} partner_name={partner_name} rider_account={rider_account} communities={communities} communitiesAuthList={communitiesAuthList}/>];

            }*/
          }
        }
        return mainComponents;
              
    }
    //mainComponents.push(<FetchAuthStrategy />);
    } else {
      mainComponents = [
        //<FetchCommunityStrategy />,
        <BrowserRouter>
          <Switch>
            {indexRoutes.public.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </BrowserRouter>
      ];
    }
    return mainComponents;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.userData.isAuthenticated
  };
};
export default connect(mapStateToProps)(AppComponent);