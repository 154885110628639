import React, { Component } from 'react';
import styles from './OrderRideStyle.module.css';
import { Col, Grid, Row } from "react-bootstrap";
import classnames from "classnames";
import { convertToToken } from "../../helpers/paymentHelper";
import i18n from "i18next";
import PhoneInput from 'react-phone-input-2';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Switch from "react-switch";
import 'react-phone-input-2/lib/style.css'
import { removeAccents } from '../util';
import { CurrencyFormatter } from '../../helpers/CurrencyFormatter';

const TIP_SELECTION = {
  _0: 0,
  _10: 1,
  _15: 2,
  _20: 3,
  _other: 4
}

class OrderRide3 extends Component {

  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      phoneError: false,
      disableButton: false,
      tipSelection: TIP_SELECTION._15,
      tipFiatAmount: 20,
      quote: props.selectedQuote.amount,
      currency: props.selectedQuote.currency,
    }
  }

  componentDidMount() {
    this.handleTipSelectionChange(TIP_SELECTION._15);
  }

  handleChangePhoneExtension = (e) => {
    if (!isNaN(e.target.value) || e.target.value.length === 0) {
      this.props.onChange({ [e.target.name]: e.target.value });
    }
  }

  validateForm = () => {
    let valid = true;
    const { pickupPhone } = this.props.data;
    if (pickupPhone && (pickupPhone.length < 7 || pickupPhone.length > 15)) {
      this.setState({ phoneError: true });
      valid = false;
    }

    return valid;
  }

  handleTipSelectionChange = (tipSelection, otherValue=null) => {
    let tipFiatAmount = 0;
    switch (tipSelection) {
      case TIP_SELECTION._0:
        tipFiatAmount = 0;
        break;
      case TIP_SELECTION._10:
        tipFiatAmount = CurrencyFormatter.convertToUnit(this.state.quote * 0.10, this.state.currency);
        break;
      case TIP_SELECTION._15:
        tipFiatAmount = CurrencyFormatter.convertToUnit(this.state.quote * 0.15, this.state.currency);
        break;
      case TIP_SELECTION._20:
        tipFiatAmount = CurrencyFormatter.convertToUnit(this.state.quote * 0.20, this.state.currency);
        break;
      case TIP_SELECTION._other:
        tipFiatAmount = otherValue
        break;
      default:
        tipFiatAmount = 0;
        break;
    }

    const tipTokenAmount = CurrencyFormatter.convertFromUnit(tipFiatAmount, this.state.currency);

    this.setState({ tipFiatAmount, tipSelection })
    this.props.onChange({ tipTokenAmount });
  }

  render() {
    const {
      pickupCompanyName,
      pickupPhone,
      pickupPhoneExt,
      orderReferenceNumber,
      disableCallRideButton
    } = this.props.data;
    const { onChange } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <Grid className={styles.formContainer}>
          <Row>
            <Col md={12}>
              <div className={styles.center}>
                <p className={styles.optionalTitle}>
                  {i18n.t("COMPANY_STEP_TITLE")}
                </p>
                <div className={styles.line6}></div>
                <p className={styles.subtitle}>
                  {i18n.t("SENDING_COMPANY_INFO_MESSAGE")}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className={styles.formTitle}>
                {i18n.t("COMPANY_NAME")}
              </p>
              <input
                name="pickupCompanyName"
                value={removeAccents(pickupCompanyName)}
                className={styles.inputForm}
                tabIndex={1}
                onChange={(e) => onChange({ [e.target.name]: removeAccents(e.target.value) })}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <p className={styles.formTitle}>
                {i18n.t("TELEPHONE")}
              </p>
              <PhoneInput
                inputProps={{
                  name: "pickupPhone",
                  tabIndex: 2,
                }}
                value={pickupPhone}
                inputClass={classnames(styles.inputForm, styles.phoneInput, this.state.phoneError ? styles.inputFormError : "")}
                country="ca"
                masks={{"ci": '.. .. .. .. ..'}}
                specialLabel=""
                placeholder={i18n.t("TELEPHONE_PLACEHOLDER")}
                preferredCountries={['ca']}
                onChange={(value) => {
                  if (this.state.phoneError) {
                    this.setState({ phoneError: false });
                  }
                  onChange({ pickupPhone: value });
                }}
                containerClass={styles.phoneInputContainer}
                buttonClass={styles.phoneDropdownButton}
              />
            </Col>
            <Col md={3}>
              <p className={classnames(styles.formTitle, styles.fieldNoHeader)}></p>
              <input
                name="pickupPhoneExt"
                value={pickupPhoneExt}
                placeholder={i18n.t("TEL_EXTENSION_PLACEHOLDER")}
                className={styles.inputForm}
                tabIndex={3}
                onChange={(e) => this.handleChangePhoneExtension(e)}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className={styles.formTitle}>
                {i18n.t("ORDER_NUMBER_REFERENCE")}
              </p>
              <input
                name="orderReferenceNumber"
                value={removeAccents(orderReferenceNumber)}
                className={styles.inputForm}
                tabIndex={4}
                maxLength="20"
                onChange={(e) => onChange({ [e.target.name]: removeAccents(e.target.value) })}>
              </input>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={styles.center}>
            <p className={styles.formTitle}>
                {i18n.t("TIP")}
              </p>
              <div className={styles.centerInput}>
              <input
                name="tipAmountFiat"
                value={this.state.tipFiatAmount}
                className={styles.inputFormTip}
                tabIndex={5}
                onChange={(e) => this.handleTipSelectionChange(TIP_SELECTION._other, e.target.value.replace(',', '.'))}
                // onBlur={(e) => this.setState({ tipFiatAmount: e.target.value.replace(',', '.')})}
                >
              </input>
              <input
                name="tipAmountFiat"
                value={this.props.selectedQuote.currency}
                className={styles.inputFormTipCurrency}
                tabIndex={6}
                readOnly={true} >

              </input>
              </div>
              <div className={styles.tipPercentageSelectionContainer}>
                  <div onClick={() => this.handleTipSelectionChange(TIP_SELECTION._0)} className={classnames(styles.tipPercentageSelectionItem, this.state.tipSelection === TIP_SELECTION._0 ? styles.active: null)}>
                      0%
                  </div>
                  <div onClick={() => this.handleTipSelectionChange(TIP_SELECTION._10)} className={classnames(styles.tipPercentageSelectionItem, this.state.tipSelection === TIP_SELECTION._10 ? styles.active: null)}>
                      10%
                  </div>
                  <div onClick={() => this.handleTipSelectionChange(TIP_SELECTION._15)} className={classnames(styles.tipPercentageSelectionItem, this.state.tipSelection === TIP_SELECTION._15 ? styles.active: null)}>
                      15%
                  </div>
                  <div onClick={() => this.handleTipSelectionChange(TIP_SELECTION._20)} className={classnames(styles.tipPercentageSelectionItem, this.state.tipSelection === TIP_SELECTION._20 ? styles.active: null)}>
                      20%
                  </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <button
                disabled={disableCallRideButton}
                className={styles.nextBtn}
                onClick={() => {
                  if (this.validateForm()) {
                    this.props.nextStepClick();
                  }
                }}>
                <p className={styles.nextText}>
                  {i18n.t("ORDER_RIDE_CONFIRM")}
                </p>
              </button>
            </Col>
          </Row>
        </Grid>
      </div>);
  };
}

export default OrderRide3;