export function isEmptyObject(obj) {
  return obj == null || Object.keys(obj).length === 0;
}

export function removeAccents(str) {
  if (!str) {
    return str;
  }
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function removeAccentsFromMapKeys(inputMap) {
  const modifiedMap = {};

  for (const key in inputMap) {
    if (inputMap.hasOwnProperty(key)) {
      const modifiedKey = removeAccents(key);
      modifiedMap[modifiedKey] = inputMap[key];
    }
  }

  return modifiedMap;
}